import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./carousel.css";
import s3 from "./images/s3.png";
import lambda from "./images/lambda.png";
import cloudWatch from "./images/cloudWatch.png";
import eC2 from "./images/eC2.png";
import ecr from "./images/ecr.png";
import ecs from "./images/ecs.png";
import route53 from "./images/route53.png";
import loadbalancing from "./images/loadbalancing.png";
import cicd from "./images/cicd.png";
import docker from "./images/docker.png";
import nginx from "./images/nginx.png";

const DevOpsCarousel = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    swipeToSlide: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="container text-white">
      <Slider {...settings}>
        <div className="custom">
          <div className="d-flex flex-column h-full w-full justify-content-center align-items-center">
            <img src={s3} alt="s3" />
            <p>S3</p>
          </div>
        </div>
        <div className="custom">
          <div className="d-flex flex-column h-full w-full justify-content-center align-items-center">
            <img src={lambda} alt="lambda" />
            <p>Lambda</p>
          </div>
        </div>
        <div className="custom">
          <div className="d-flex flex-column h-full w-full justify-content-center align-items-center">
            <img src={cloudWatch} alt="cloudWatch" />
            <p>Cloud Watch</p>
          </div>
        </div>
        <div className="custom">
          <div className="d-flex flex-column h-full w-full justify-content-center align-items-center">
            <img src={eC2} alt="eC2" />
            <p>EC2</p>
          </div>
        </div>
        
        <div className="custom">
          <div className="d-flex flex-column h-full w-full justify-content-center align-items-center">
            <img src={ecr} alt="ecr" />
            <p>ECR</p>
          </div>
        </div>
        
        <div className="custom">
          <div className="d-flex flex-column h-full w-full justify-content-center align-items-center">
            <img src={ecs} alt="ecs" />
            <p>ECS</p>
          </div>
        </div>
        
        <div className="custom">
          <div className="d-flex flex-column h-full w-full justify-content-center align-items-center">
            <img src={route53} alt="route53" />
            <p>Route53</p>
          </div>
        </div>
        <div className="custom">
          <div className="d-flex flex-column h-full w-full justify-content-center align-items-center">
            <img src={loadbalancing} alt="loadbalancing" />
            <p>Load Balancing</p>
          </div>
        </div>
        <div className="custom">
          <div className="d-flex flex-column h-full w-full justify-content-center align-items-center">
            <img src={cicd} alt="cicd" className="img-height" />
            <p>CICD</p>
          </div>
        </div>
        
        <div className="custom">
          <div className="d-flex flex-column h-full w-full justify-content-center align-items-center">
            <img src={docker} alt="docker" className="img-height" />
            <p>Docker</p>
          </div>
        </div>
        <div className="custom">
          <div className="d-flex flex-column h-full w-full justify-content-center align-items-center">
            <img src={nginx} alt="nginx" className="img-height" />
            <p>Nginx</p>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default DevOpsCarousel;
