import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import "./Portfolio7.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "react-bootstrap";
import solutions121 from "../images/121solutions.png";

const Portfolio7 = () => {
  useEffect(() => {
    AOS.init({
      duration: 800,
      once: true,
    });
  }, []);
  return (
    <div className="port7_main">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-sm-12 port7_left" data-aos="zoom-in-right">
            <a href="https://solutions.121.us/" rel="noreferrer">
              <img
                className=""
                src={solutions121}
                width="100%"
                alt="Fourthimg"
              />
            </a>
          </div>
          <div class="col-lg-6 col-sm-12 port7_right" data-aos="zoom-in-down">
            <h2>
              <strong>121 Solutions</strong>
            </h2>
            <h4>
              <span>
                World Class Experience In Technology, Consulting, And Software
              </span>
            </h4>
            <p>
              121 Solutions is a Technology, consulting, and software
              development company present in the USA.
            </p>
            <a href="https://solutions.121.us/" target={"_blank"}>
              <Button className="mt-2 shadow-lg porftfolio7_btn">
                VISIT SITE
              </Button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Portfolio7;
