import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Portfolio13.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "react-bootstrap";
import Apiax from "../images/Colorlaza.png";

const Portfolio13 = () => {
  useEffect(() => {
    AOS.init({
      duration: 800,
      once: true,
    });
  }, []);
  return (
    <div className="port13_main">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-sm-12 port13_left" data-aos="zoom-in-right">
            <a href="" rel="noreferrer">
              <img className="" src={Apiax} width="100%" alt="Fourthimg" />
            </a>
          </div>
          <div class="col-lg-6 col-sm-12 port13_right" data-aos="zoom-in-down">
            <h2>
              <strong>Colorlaza</strong>
            </h2>
            <h4>
              <span>We Provide Quality Products.</span>
            </h4>
            <p>
              These colored metal buckets can be used for tabletop storage
              barrels, pen holders, and makeup brush holders. It organizes all
              bits and pieces around your house, it also works well for kids,
              centerpieces, sortings, classrooms, crafts, and party favors.
            </p>
            <a href="https://colorlaza.com/" target={"_blank"}>
              <Button className="mt-2 shadow-lg porftfolio13_btn">
                VISIT SITE
              </Button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Portfolio13;
