import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Portfolio11.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "react-bootstrap";
import Einzzi from "../images/einzzi.png";

const Portfolio11 = () => {
  useEffect(() => {
    AOS.init({
      duration: 800,
      once: true,
    });
  }, []);
  return (
    <div
      className="port11_main shadow-lg"
      style={{ backgroundColor: "#313647;" }}
    >
      <div class="container">
        <div class="row">
          <div
            className="col-lg-6 col-sm-12 port11_left "
            data-aos="zoom-in-right"
          >
            <ul>
              <li>
                <div class="image">
                  <a
                    href="https://einzzi.com/"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <img src={Einzzi} width="100%" alt="Fourthimg" />
                  </a>
                </div>
                <div class="shadow"></div>
              </li>
            </ul>
          </div>
          <div class="col-lg-6 col-sm-12 port11_right" data-aos="zoom-in-down">
            <h2>
              <strong>EINZZI</strong>
            </h2>
            <h4>
              <span>The Shopper's Protection.</span>
            </h4>
            <p>
              Adhesive pads from well-known brand manufacturers to achieve an
              extra strong hold of up to 10 kg. Black, matte finish with powder
              coating gives the toilet paper holder the finishing touch.
            </p>
            <a href="https://einzzi.com/" target={"_blank"}>
              <Button className="mt-2 shadow-lg portfolio11_btn">
                VISIT SITE
              </Button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Portfolio11;
