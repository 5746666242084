import React, { Component } from "react";
import "./Portfolio19.css";
import Slider from "react-slick";
import eztracking from "../images/eztracking.png";
import eztracking1 from "../images/eztracking1.png";
import eztracking2 from "../images/eztracking2.png";
import { Button } from "react-bootstrap";
import DevOpsCarousel from "./carousel/DevOpsCarousel";
const Portfolio19 = () => {
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    arrows: false,
    centerPadding: "0px",
    slidesToShow: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
  };

  return (
    <div className="container-fluid port19_main">
      <div className="container">
        <div className="row">
          <div className="col-lg-5  port19_right">
            <h2>
              <strong>2EZ Tracking</strong>
            </h2>
            <h4>
              <span>Track & Manage Construction Costs.</span>
            </h4>
            <p>
              Real-Time cost tracking is a need in the construction industry!
              Statistically, around 85% of construction projects clock in over
              budget due to some combination of poor costs tracking or data
              errors.
            </p>
            <a href="https://app.2eztracking.com/" target={"_blank"}>
              <Button className="shadow-lg portfolio19_btn">VISIT SITE</Button>
            </a>
            <div className="mt-4">
              <ul className="list-unstyled d-flex flex-row">
                <li className="mx-2 border py-1 px-2 custom-rounded">
                  Reactjs
                </li>
                <li className="mx-2 border py-1 px-2 custom-rounded">Nodejs</li>
                <li className="mx-2 border py-1 px-2 custom-rounded">
                  Tailwind Css
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-7  port19_left">
            <div className="text-center">
              <Slider {...settings}>
                <div>
                  <img src={eztracking} alt="eztracking" />
                </div>
                <div>
                  <img src={eztracking1} alt="eztracking" />
                </div>
                <div>
                  <img src={eztracking2} alt="eztracking" />
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
      <DevOpsCarousel />
    </div>
  );
};

export default Portfolio19;
