import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./ContactUsComponent.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { useRef } from "react";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const countries = [
  { code: "+93", name: "Afghanistan" },
  { code: "+355", name: "Albania" },
  { code: "+213", name: "Algeria" },
  { code: "+376", name: "Andorra" },
  { code: "+244", name: "Angola" },
  { code: "+54", name: "Argentina" },
  { code: "+374", name: "Armenia" },
  { code: "+297", name: "Aruba" },
  { code: "+61", name: "Australia" },
  { code: "+43", name: "Austria" },
  { code: "+994", name: "Azerbaijan" },
  { code: "+973", name: "Bahrain" },
  { code: "+880", name: "Bangladesh" },
  { code: "+375", name: "Belarus" },
  { code: "+32", name: "Belgium" },
  { code: "+501", name: "Belize" },
  { code: "+229", name: "Benin" },
  { code: "+975", name: "Bhutan" },
  { code: "+591", name: "Bolivia" },
  { code: "+387", name: "Bosnia and Herzegovina" },
  { code: "+267", name: "Botswana" },
  { code: "+55", name: "Brazil" },
  { code: "+246", name: "British Indian Ocean Territory" },
  { code: "+673", name: "Brunei" },
  { code: "+359", name: "Bulgaria" },
  { code: "+226", name: "Burkina Faso" },
  { code: "+257", name: "Burundi" },
  { code: "+855", name: "Cambodia" },
  { code: "+237", name: "Cameroon" },
  { code: "+1", name: "Canada" },
  { code: "+238", name: "Cape Verde" },
  { code: "+236", name: "Central African Republic" },
  { code: "+235", name: "Chad" },
  { code: "+56", name: "Chile" },
  { code: "+86", name: "China" },
  { code: "+57", name: "Colombia" },
  { code: "+269", name: "Comoros" },
  { code: "+243", name: "Congo (DR)" },
  { code: "+242", name: "Congo (CG)" },
  { code: "+506", name: "Costa Rica" },
  { code: "+225", name: "Côte d'Ivoire" },
  { code: "+385", name: "Croatia" },
  { code: "+53", name: "Cuba" },
  { code: "+599", name: "Curaçao" },
  { code: "+357", name: "Cyprus" },
  { code: "+420", name: "Czech Republic" },
  { code: "+45", name: "Denmark" },
  { code: "+253", name: "Djibouti" },
  { code: "+1", name: "Dominica" },
  { code: "+1", name: "Dominican Republic" },
  { code: "+593", name: "Ecuador" },
  { code: "+20", name: "Egypt" },
  { code: "+503", name: "El Salvador" },
  { code: "+240", name: "Equatorial Guinea" },
  { code: "+291", name: "Eritrea" },
  { code: "+372", name: "Estonia" },
  { code: "+251", name: "Ethiopia" },
  { code: "+500", name: "Falkland Islands (Malvinas)" },
  { code: "+298", name: "Faroe Islands" },
  { code: "+679", name: "Fiji" },
  { code: "+358", name: "Finland" },
  { code: "+33", name: "France" },
  { code: "+594", name: "French Guiana" },
  { code: "+689", name: "French Polynesia" },
  { code: "+241", name: "Gabon" },
  { code: "+220", name: "Gambia" },
  { code: "+995", name: "Georgia" },
  { code: "+49", name: "Germany" },
  { code: "+233", name: "Ghana" },
  { code: "+350", name: "Gibraltar" },
  { code: "+30", name: "Greece" },
  { code: "+299", name: "Greenland" },
  { code: "+590", name: "Guadeloupe" },
  { code: "+502", name: "Guatemala" },
  { code: "+44", name: "Guernsey" },
  { code: "+224", name: "Guinea" },
  { code: "+245", name: "Guinea-Bissau" },
  { code: "+592", name: "Guyana" },
  { code: "+509", name: "Haiti" },
  { code: "+504", name: "Honduras" },
  { code: "+852", name: "Hong Kong" },
  { code: "+36", name: "Hungary" },
  { code: "+354", name: "Iceland" },
  { code: "+91", name: "India" },
  { code: "+62", name: "Indonesia" },
  { code: "+98", name: "Iran" },
  { code: "+964", name: "Iraq" },
  { code: "+353", name: "Ireland" },
  { code: "+44", name: "Isle of Man" },
  { code: "+972", name: "Israel" },
  { code: "+39", name: "Italy" },
  { code: "+1", name: "Jamaica" },
  { code: "+81", name: "Japan" },
  { code: "+44", name: "Jersey" },
  { code: "+962", name: "Jordan" },
  { code: "+7", name: "Kazakhstan" },
  { code: "+254", name: "Kenya" },
  { code: "+686", name: "Kiribati" },
  { code: "+965", name: "Kuwait" },
  { code: "+996", name: "Kyrgyzstan" },
  { code: "+856", name: "Laos" },
  { code: "+371", name: "Latvia" },
  { code: "+961", name: "Lebanon" },
  { code: "+961", name: "Lebanon" },
  { code: "+231", name: "Liberia" },
  { code: "+218", name: "Libya" },
  { code: "+423", name: "Liechtenstein" },
  { code: "+370", name: "Lithuania" },
  { code: "+352", name: "Luxembourg" },
  { code: "+853", name: "Macau" },
  { code: "+389", name: "North Macedonia" },
  { code: "+261", name: "Madagascar" },
  { code: "+265", name: "Malawi" },
  { code: "+60", name: "Malaysia" },
  { code: "+960", name: "Maldives" },
  { code: "+223", name: "Mali" },
  { code: "+356", name: "Malta" },
  { code: "+692", name: "Marshall Islands" },
  { code: "+222", name: "Mauritania" },
  { code: "+230", name: "Mauritius" },
  { code: "+52", name: "Mexico" },
  { code: "+691", name: "Micronesia" },
  { code: "+373", name: "Moldova" },
  { code: "+377", name: "Monaco" },
  { code: "+976", name: "Mongolia" },
  { code: "+382", name: "Montenegro" },
  { code: "+212", name: "Morocco" },
  { code: "+258", name: "Mozambique" },
  { code: "+95", name: "Myanmar" },
  { code: "+264", name: "Namibia" },
  { code: "+674", name: "Nauru" },
  { code: "+977", name: "Nepal" },
  { code: "+31", name: "Netherlands" },
  { code: "+687", name: "New Caledonia" },
  { code: "+64", name: "New Zealand" },
  { code: "+505", name: "Nicaragua" },
  { code: "+227", name: "Niger" },
  { code: "+234", name: "Nigeria" },
  { code: "+683", name: "Niue" },
  { code: "+672", name: "Norfolk Island" },
  { code: "+850", name: "North Korea" },
  { code: "+47", name: "Norway" },
  { code: "+968", name: "Oman" },
  { code: "+92", name: "Pakistan" },
  { code: "+970", name: "Palestine" },
  { code: "+507", name: "Panama" },
  { code: "+675", name: "Papua New Guinea" },
  { code: "+595", name: "Paraguay" },
  { code: "+51", name: "Peru" },
  { code: "+63", name: "Philippines" },
  { code: "+48", name: "Poland" },
  { code: "+351", name: "Portugal" },
  { code: "+974", name: "Qatar" },
  { code: "+262", name: "Reunion" },
  { code: "+40", name: "Romania" },
  { code: "+7", name: "Russia" },
  { code: "+250", name: "Rwanda" },
  { code: "+590", name: "Saint Barthelemy" },
  { code: "+290", name: "Saint Helena" },
  { code: "+1869", name: "Saint Kitts and Nevis" },
  { code: "+1758", name: "Saint Lucia" },
  { code: "+590", name: "Saint Martin" },
  { code: "+508", name: "Saint Pierre and Miquelon" },
  { code: "+1784", name: "Saint Vincent and the Grenadines" },
  { code: "+685", name: "Samoa" },
  { code: "+378", name: "San Marino" },
  { code: "+239", name: "Sao Tome and Principe" },
  { code: "+966", name: "Saudi Arabia" },
  { code: "+221", name: "Senegal" },
  { code: "+381", name: "Serbia" },
  { code: "+248", name: "Seychelles" },
  { code: "+232", name: "Sierra Leone" },
  { code: "+65", name: "Singapore" },
  { code: "+1721", name: "Sint Maarten" },
  { code: "+421", name: "Slovakia" },
  { code: "+386", name: "Slovenia" },
  { code: "+677", name: "Solomon Islands" },
  { code: "+252", name: "Somalia" },
  { code: "+27", name: "South Africa" },
  { code: "+82", name: "South Korea" },
  { code: "+211", name: "South Sudan" },
  { code: "+34", name: "Spain" },
  { code: "+94", name: "Sri Lanka" },
  { code: "+249", name: "Sudan" },
  { code: "+597", name: "Suriname" },
  { code: "+47", name: "Svalbard and Jan Mayen" },
  { code: "+268", name: "Swaziland" },
  { code: "+46", name: "Sweden" },
  { code: "+41", name: "Switzerland" },
  { code: "+963", name: "Syria" },
  { code: "+886", name: "Taiwan" },
  { code: "+992", name: "Tajikistan" },
  { code: "+255", name: "Tanzania" },
  { code: "+66", name: "Thailand" },
  { code: "+670", name: "Timor-Leste" },
  { code: "+228", name: "Togo" },
  { code: "+690", name: "Tokelau" },
  { code: "+676", name: "Tonga" },
  { code: "+1868", name: "Trinidad and Tobago" },
  { code: "+216", name: "Tunisia" },
  { code: "+90", name: "Turkey" },
  { code: "+993", name: "Turkmenistan" },
  { code: "+1649", name: "Turks and Caicos Islands" },
  { code: "+688", name: "Tuvalu" },
  { code: "+256", name: "Uganda" },
  { code: "+380", name: "Ukraine" },
  { code: "+971", name: "United Arab Emirates" },
  { code: "+44", name: "United Kingdom" },
  { code: "+1", name: "United States" },
  { code: "+598", name: "Uruguay" },
  { code: "+998", name: "Uzbekistan" },
  { code: "+678", name: "Vanuatu" },
  { code: "+58", name: "Venezuela" },
  { code: "+84", name: "Vietnam" },
  { code: "+681", name: "Wallis and Futuna" },
  { code: "+212", name: "Western Sahara" },
  { code: "+967", name: "Yemen" },
  { code: "+260", name: "Zambia" },
  { code: "+263", name: "Zimbabwe" },
];

function ContactUsComponent() {
  const form = useRef();
  const navigate = useNavigate();

  const [projectName, setProjectName] = useState("");
  const [customerType, setCustomerType] = useState("new");
  const [projectCategories, setProjectCategories] = useState([]);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [projectTimeline, setProjectTimeline] = useState("");
  const [SelectBudget, setSelectBudget] = useState("");
  const [FullName, setFullName] = useState("");
  const [countryCode, setCountryCode] = useState(countries[0].code);
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleCountryCodeChange = (event) => {
    setCountryCode(event.target.value);
  };

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleProjectNameChange = (e) => {
    setProjectName(e.target.value);
  };

  const handleCustomerTypeChange = (e) => {
    setCustomerType(e.target.value);
  };
  const handleCategoryChange = (e) => {
    const category = e.target.value;
    if (projectCategories.includes(category)) {
      setProjectCategories(projectCategories.filter((c) => c !== category));
    } else {
      setProjectCategories([...projectCategories, category]);
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const handleProjectDescriptionChange = (e) => {
    setProjectDescription(e.target.value);
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_8fglin5",
        "template_edxn25v",
        form.current,
        "gG8VfasCz6bCZQNwK"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    setProjectName("");
    setCustomerType("");
    setProjectCategories("");
    setEmail("");
    setPhone("");
    setProjectDescription("");
    setProjectTimeline("");
    setSelectBudget("");
    setFullName("");
    setCountryCode("");
    setPhoneNumber("");

    navigate("/");
    toast.success("Email Sent Successfully!", { autoClose: 5000 });
  };

  return (
    <div className="main_contactus">
      <section id="contact" className="contact">
        <div className="container" data-aos="fade-up">
          <div className="row gx-lg-0 gy-4 shadow-lg">
            <div className="col-lg-4">
              <div className="info-container d-flex flex-column align-items-center justify-content-center">
                <div className="info-item d-flex">
                  <i className="flex-shrink-0">
                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                  </i>
                  <div>
                    <h4>Location:</h4>
                    <p>
                      Hassan Colony, Taxila Cantt, Distt. rawalpindi, Punjab,
                      Pakistan.
                    </p>
                  </div>
                </div>

                <div className="info-item d-flex">
                  <i className="flex-shrink-0">
                    <FontAwesomeIcon icon={faEnvelope} />
                  </i>
                  <div>
                    <h4>Email:</h4>
                    <p>comtechradix@gmail.com</p>
                  </div>
                </div>

                <div className="info-item d-flex">
                  <i className="flex-shrink-0">
                    <FontAwesomeIcon icon={faPhone} />
                  </i>
                  <div>
                    <h4>Call Us:</h4>
                    <p>+92 316 7935721</p>
                  </div>
                </div>

                <div className="info-item d-flex">
                  <i className="flex-shrink-0">
                    <FontAwesomeIcon icon={faClock} />
                  </i>
                  <div>
                    <h4>Open Hours:</h4>
                    <p>Mon-Sat: 11:00 - 23:00</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-8">
              <form onSubmit={sendEmail} ref={form} className="php-email-form">
                <div className="row">
                  <h4>
                    <b>Project Details</b>
                  </h4>
                  <div className="col-lg-6 col-md-12 col-sm-12 form-group mt-3">
                    <label>
                      <b>Project Name *</b>
                    </label>
                    <br />
                    <input
                      className="form-control mt-1"
                      type="text"
                      value={projectName}
                      onChange={handleProjectNameChange}
                      name="project_name"
                      required
                    />
                  </div>
                  <div className=" col-lg-6 col-md-12 col-sm-12 form-group mt-3">
                    <label>
                      <b>New or existing customer? *</b>
                    </label>
                    <br />
                    <select
                      className="form-control mt-1"
                      value={customerType}
                      onChange={handleCustomerTypeChange}
                      required
                      name="customer"
                    >
                      <option value="New Customer">New Customer</option>
                      <option value="Existing Customer">
                        Existing Customer
                      </option>
                    </select>
                  </div>
                  <div className="col-lg-12 col-md-12 project_category form-group mt-3 mt-md-0">
                    <label>
                      <b>Project Category</b>
                    </label>
                    <br />
                    <input
                      name="project_category"
                      type="checkbox"
                      value="frontend"
                      checked={projectCategories.includes("frontend")}
                      onChange={handleCategoryChange}
                    />{" "}
                    Frontend
                    <br />
                    <input
                      name="project_category"
                      type="checkbox"
                      value="backend"
                      checked={projectCategories.includes("backend")}
                      onChange={handleCategoryChange}
                    />{" "}
                    Backend
                    <br />
                    <input
                      name="project_category"
                      type="checkbox"
                      value="WordPress"
                      checked={projectCategories.includes("WordPress")}
                      onChange={handleCategoryChange}
                    />{" "}
                    WordPress
                    <br />
                    <input
                      name="project_category"
                      type="checkbox"
                      value="ui/ux"
                      checked={projectCategories.includes("ui/ux")}
                      onChange={handleCategoryChange}
                    />{" "}
                    UI / UX
                  </div>

                  <div className=" col-lg-6 col-md-12 form-group mt-3 mt-md-0">
                    <label htmlFor="projectTimeline">
                      <b>Project Timeline *</b>
                    </label>
                    <br />
                    <select
                      name="project_timeline"
                      id="projectTimeline"
                      value={projectTimeline}
                      onChange={(event) =>
                        setProjectTimeline(event.target.value)
                      }
                      required
                      className="form-control mt-1"
                    >
                      <option value="">Select Timeline</option>
                      <option value="Less than 1 week">Less than 1 week</option>
                      <option value="1 month1">1 month</option>
                      <option value="1-3 months">1-3 months</option>
                      <option value="3-6 months or more">3-6 months</option>
                      <option value="More than 6 months">
                        More than 6 months
                      </option>
                      <option value="Not sure">Not sure</option>
                    </select>
                  </div>
                  <div className=" col-lg-6 col-md-12 form-group mt-3 mt-md-0">
                    <label htmlFor="SelectBudget">
                      <b>Select your budget *</b>
                    </label>
                    <br />
                    <select
                      name="project_budget"
                      id="SelectBudget"
                      value={SelectBudget}
                      onChange={(event) => setSelectBudget(event.target.value)}
                      required
                      className="form-control mt-1"
                    >
                      <option value="">Select budget in USD.</option>
                      <option value="5$-10$">5$-20$</option>
                      <option value="20$-50$">20$-50$</option>
                      <option value="50$-100$">50$-200$</option>
                      <option value="200$-500$">200$-500$</option>
                      <option value="500$-1000$">500$-1000$</option>
                      <option value="1000$-5000$">1000$-2000$</option>
                      <option value="1000$-5000$">2000$-5000$</option>
                    </select>
                  </div>
                  <h4 className="mt-3">
                    <b>Contact Information</b>
                  </h4>
                  <div className="col-lg-6 col-md-12 form-group mt-3">
                    <label htmlFor="FullName">
                      <b>Your Full Name *</b>
                    </label>
                    <input
                      name="full_name"
                      type="text"
                      id="FullName"
                      value={FullName}
                      onChange={(event) => setFullName(event.target.value)}
                      required
                      className="form-control mt-1"
                    />
                  </div>
                  <div className="col-lg-6 col-md-12 form-group mt-3">
                    <label htmlFor="email">
                      <b>Email Address *</b>
                    </label>
                    <input
                      name="email"
                      id="email"
                      type="email"
                      value={email}
                      onChange={handleEmailChange}
                      required
                      className="form-control mt-1"
                    />
                  </div>
                  <div className="col-lg-12 col-md-12 form-group mt-1">
                    <label htmlFor="projectDescription">
                      <b>Mobile Number *</b>
                    </label>
                    <div className="mobile-number-input">
                      <select
                        className="country_code"
                        value={countryCode}
                        onChange={handleCountryCodeChange}
                      >
                        {countries.map((country) => (
                          <option key={country.code} value={country.code}>
                            {country.name} ({country.code})
                          </option>
                        ))}
                      </select>
                      <input
                        name="phone_number"
                        type="tel"
                        value={phoneNumber}
                        onChange={handlePhoneNumberChange}
                        placeholder="Enter your phone number"
                        required
                        className="phone_number mt-1"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 form-group mt-1">
                    <label htmlFor="projectDescription">
                      <b>Project description *</b>
                    </label>
                    <textarea
                      id="projectDescription"
                      rows="5"
                      value={projectDescription}
                      onChange={handleProjectDescriptionChange}
                      required
                      className="form-control mt-1"
                      placeholder="Enter Your Project Description..."
                      name="project_description"
                    />
                  </div>
                </div>
                <div className="text-center">
                  <button type="submit">Send Message</button>
                </div>
              </form>

              {/* make a reactjs form with project name as ( input field), are you a new or existing customer as (select), project category as (check boxes), project timeline as(select), select your budget as(select), full name as (input), email address as (input), mobile number (input with country select), project description as (text area), and a send message (submit button). */}
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </div>
  );
}

export default ContactUsComponent;
