import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./PortfolioHero.css";
import "bootstrap/dist/css/bootstrap.min.css";

const PortfolioHero = () => {
  useEffect(() => {
    AOS.init({
      duration: 800,
      once: true,
    });
  }, []);
  return (
    <div className="PortfolioHero">
      <h1 data-aos="fade-up">Explore Our Work</h1>
      <p data-aos="zoom-in">
        The products <a href="https://www.comtechradix.com/">Com Tech Radix</a> is building
        are changing the way industry leaders do their core business
      </p>
    </div>
  );
};

export default PortfolioHero;
