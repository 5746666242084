import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./AboutUsHero.css";
import "bootstrap/dist/css/bootstrap.min.css";

const AboutUsHero = () => {
  useEffect(() => {
    AOS.init({
      duration: 800,
      once: true,
    });
  }, []);
  return (
    <div className="AboutUsHero">
      <h1 data-aos="fade-up">About Us</h1>
      <p data-aos="zoom-in">
        Welcome to <a href="https://www.comtechradix.com/">Com Tech Radix</a>, a
        leading software development company that provides cutting-edge
        solutions to businesses of all sizes.
      </p>
    </div>
  );
};

export default AboutUsHero;
