import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Portfolio3.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "react-bootstrap";
import smartermergers1 from "../images/smartermergers1.png";
import smartermergers2 from "../images/smartermergers2.png";
import smartermergers3 from "../images/smartermergers3.png";
import smartermergers4 from "../images/smartermergers4.png";

const HomePortfolioRow = () => {
  useEffect(() => {
    AOS.init({
      duration: 800,
      once: true,
    });
  }, []);
  return (
    <div
      className="port_main3 shadow-lg"
      style={{ backgroundColor: "#313647;" }}
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-sm-12 Port3_left" data-aos="zoom-in-right">
            <div class="gallery">
              <img src={smartermergers1} width="100%" alt="Fourthimg" />
              <img src={smartermergers2} width="100%" alt="Fourthimg" />
              <img src={smartermergers3} width="100%" alt="Fourthimg" />
              <img src={smartermergers4} width="100%" alt="Fourthimg" />
            </div>
            {/* <img src={Synch} width='100%' alt='Fourthimg'/> */}
          </div>
          <div class="col-lg-6 col-sm-12 port3_right" data-aos="zoom-in-down">
            <h2>
              <strong>SMARTER MERGERS</strong>
            </h2>
            <h4>
              <span>Acquire, Sell or Finance Businesses.</span>
            </h4>
            <p>
              SMARTER MERGERS is a Boutique mergers and acquisitions firm in the
              Mid-Atlantic region. They work with and for business owners who
              want to sell their businesses, providing the highest level of
              service and attention.
            </p>
            <a href="https://smartermergers.com/" target={"_blank"}>
              <Button className="mt-2 shadow-lg porftfolio3_btn">
                VISIT SITE
              </Button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePortfolioRow;
