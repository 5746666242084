import React, { Component } from "react";
import "./Portfolio18.css";
import Slider from "react-slick";
import possiblyDashboard from "../images/possiblyDashboard.jpg";
import possiblyDashboard2 from "../images/possiblyDashboard2.jpg";
import possiblyDashboard3 from "../images/possiblyDashboard3.jpg";
import DevOpsCarousel from "./carousel/DevOpsCarousel";

const Portfolio18 = () => {
  const settings = {
    className: "center",
    arrows: false,
    centerMode: true,
    vertical: true,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
  };

  return (
    <div className="container-fluid port17_main">
      <div className="container">
        <div className="row">
        <div className="col-lg-7  port17_left">
            <div className="text-center">
              <Slider {...settings}>
                <div>
                  <img src={possiblyDashboard} alt="Irange1" />
                </div>
                <div>
                  <img src={possiblyDashboard2} alt="Irange2" />
                </div>
                <div>
                  <img src={possiblyDashboard3} alt="Irange2" />
                </div>
              </Slider>
            </div>
          </div>
          <div className="col-lg-5  port17_right">
            <h2>
              <strong>Possibly Dashboard</strong>
            </h2>
            <h4>
              <span>A React Js Admin Panel.</span>
            </h4>
            <p>
              project aims to revolutionize career exploration and professional
              development by offering a comprehensive platform that integrates various core functions.
            </p>
            <div className="mt-4">
              <ul className="list-unstyled d-flex flex-row">
                <li className="mx-2 border py-1 px-2 custom-rounded">
                  Reactjs
                </li>
                <li className="mx-2 border py-1 px-2 custom-rounded">Nodejs</li>
                <li className="mx-2 border py-1 px-2 custom-rounded">
                  Tailwind Css
                </li>
              </ul>
            </div>
          </div>
          
        </div>
      </div>
      <DevOpsCarousel />
    </div>
  );
};

export default Portfolio18;
