import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Portfolio9.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "react-bootstrap";
import educationalassistance1 from "../images/educationalassistance1.png";
import educationalassistance2 from "../images/educationalassistance2.png";
import educationalassistance3 from "../images/educationalassistance3.png";
import educationalassistance4 from "../images/educationalassistance4.png";

const Portfolio9 = () => {
  useEffect(() => {
    AOS.init({
      duration: 800,
      once: true,
    });
  }, []);

  return (
    <div
      className="port9_main shadow-lg"
      style={{ backgroundColor: "#313647;" }}
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-sm-12 Port9_left" data-aos="zoom-in-right">
            <div class="gallery">
              <img src={educationalassistance1} width="100%" alt="Fourthimg" />
              <img src={educationalassistance2} width="100%" alt="Fourthimg" />
              <img src={educationalassistance3} width="100%" alt="Fourthimg" />
              <img src={educationalassistance4} width="100%" alt="Fourthimg" />
            </div>
            {/* <img src={Synch} width='100%' alt='Fourthimg'/> */}
          </div>
          <div class="col-lg-6 col-sm-12 port9_right" data-aos="zoom-in-down">
            <h2>
              <strong>RAINBOWS & WINDOWS</strong>
            </h2>
            <h4>
              <span>A website for Educational Assistant.</span>
            </h4>
            <p>
              Under the direction of Ms. Julia Hardeman-Tsadick, Chief Executive
              Officer, Educational Assistance, has provided consultative
              services since 2001. Educational Assistance serves youth,
              families, schools, churches, and government agencies.
            </p>
            <a href="https://rainbowsandwindows.com/" target={"_blank"}>
              <Button className="mt-2 shadow-lg porftfolio9_btn">
                VISIT SITE
              </Button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Portfolio9;
