import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Portfolio2.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "react-bootstrap";
import Atk from "../images/Atk.png";

const Portfolio2 = () => {
  useEffect(() => {
    AOS.init({
      duration: 800,
      once: true,
    });
  }, []);
  return (
    <div
      className="port2_main shadow-lg"
      style={{ backgroundColor: "#313647;" }}
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-sm-12 port2_left" data-aos="zoom-in-right">
            <h2>
              <strong>WoodsWorth</strong>
            </h2>
            <h4>
              <span>WoodsWorth is a Technical Services Company of Dubai.</span>
            </h4>
            <p>
              Woodsworth is a specialized technical services company in Dubai,
              United Arab Emirates. Gypsum works for commercial and residential
              buildings, including offices, villas, and apartments, are our area
              of expertise.
            </p>
            {/* <a href="#" target={"_blank"}>
              <Button className="mt-2 shadow-lg porftfolio2_btn">
                VISIT SITE
              </Button>
            </a> */}
          </div>
          <div class="col-lg-6 col-sm-12 port2_right" data-aos="zoom-in-down">
            <a
              href="#"
              target={"_blank"}
              rel="noreferrer"
            >
              <img src={Atk} width="100%" alt="Fourthimg" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Portfolio2;
