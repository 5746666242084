import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Footer.css";
function Footer() {
  useEffect(() => {
    AOS.init({
      duration: 800,
      once: true,
    });
  }, []);

  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  useEffect(() => {
    setCurrentYear(new Date().getFullYear());
  }, []);

  return (
    <>
      <div className="">
        <footer id="footer" className="footer">
          <div className="footer-content position-relative">
            <div className="container">
              <div className="row" data-aos="fade-up">
                <div className="col-lg-6 col-md-6">
                  <div className="footer-info">
                    <h3>ComtechRadix</h3>
                    <p>
                      ComTechRadix, Hassan Colony, Taxila Cantt, <br />
                      Distt. Rawalpindi, Punjab, Pakistan.
                      <br />
                      <br />
                      <strong>Phone:</strong> +92 316 7935721
                      <br />
                      <strong>Email:</strong> comtechradix@gmail.com
                      <br />
                    </p>
                  </div>
                </div>

                <div className="col-lg-2 footer-links">
                  <h4>Links</h4>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/Portfolio">Portfolio</a>
                  </li>
                  <li>
                    <a href="/Aboutus">About Us</a>
                  </li>
                  <li>
                    <a href="/Contact">Contact Us</a>
                  </li>
                </div>

                <div className="col-lg-2 col-md-3 footer-links">
                  <h4>Our Services</h4>
                  <li>Frontend</li>
                  <li>Backend</li>
                  <li>WordPress</li>
                  <li>UI / UX</li>
                </div>

                <div className="col-lg-2 col-md-3 footer-links">
                  <h4></h4>
                  <br></br>
                  <li>
                    <a href="/Privacypolicy">Privacy policy</a>
                  </li>
                  <li>
                    <a href="/Terms">Terms & Conditions</a>
                  </li>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-legal text-center position-relative">
            <div className="container" data-aos="zoom-in">
              <div className="copyright">
                &copy; Copyright {currentYear}{" "}
                <strong>
                  <span>
                    {" "}
                    <a href="https://www.comtechradix.com//"> ComtechRadix </a>
                  </span>
                </strong>
                . All Rights Reserved
              </div>
              <div className="credits">
                Designed by <a href="https://www.comtechradix.com//">CTR</a>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}

export default Footer;
