import Container from "react-bootstrap/Container";
import "./NavbarHeader.css";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import React, { useState, useEffect } from "react";
import comtechradix from "../images/logo-03.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faLinkedin,
  faTwitter,
  faInstagram,
  faWhatsapp,
  faWhatsappSquare,
} from "@fortawesome/free-brands-svg-icons";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import GoToTopButton from "./utils/GoToTopButton";
import "aos/dist/aos.css";

function NavbarHead() {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [navbarColor, setNavbarColor] = useState("transparent");
  const [textColor, setTextColor] = useState("#fff");

  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (scrollPosition > 40) {
      setNavbarColor("#00494d");
      setTextColor("black");
    } else {
      setNavbarColor("#008374");
      setTextColor("white");
    }
  }, [scrollPosition]);

  return (
    <>
      <GoToTopButton />
      <div className="container-fluid">
        <div className="row top_main">
          <div className=" top_left col-lg-6 col-md-6 col-sm-12">
            <ul>
              <li data-aos="slide-right">
                <span>
                  <FontAwesomeIcon icon={faEnvelope} />
                </span>
                &nbsp;
                <a href="mailto:comtechradix@gmail.com">ComtechRadix</a>
              </li>
              <li data-AOS="fade-up">
                <span>
                  <FontAwesomeIcon icon={faPhone} />
                </span>
                <span>+92 316 7935721</span>
              </li>
            </ul>
          </div>
          <div className=" top_right col-lg-6 col-md-6 col-sm-12">
            <ul>
              {/* <li data-aos="zoom-in">
                <a href="https://twitter.com" className="">
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
              </li>
              <li data-aos="zoom-in">
                <a href="https://facebook.com" className="">
                  <FontAwesomeIcon icon={faFacebook} />
                </a>
              </li> */}
              <li data-aos="zoom-in">
                <a href="https://whatsapp.com/channel/0029VaCt70JAe5VlmLnHQD1F" className="">
                  <FontAwesomeIcon icon={faWhatsappSquare} />
                </a>
              </li>
              <li data-aos="zoom-in">
                <a href="https://www.linkedin.com/company/com-tech-radix/" className="">
                  <FontAwesomeIcon icon={faLinkedin} />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Navbar
        className="color-nav position-sticky"
        expand="lg"
        style={{
          maxWidth: "none",
          backgroundColor: navbarColor,
          color: textColor,
        }}
      >
        <Container
          className="color-nav-inner"
          style={{ backgroundColor: navbarColor, color: textColor }}
        >
          <Navbar.Brand href="/" data-aos="zoom-in">
            <img
              className="navbar_logo"
              src={comtechradix}
              alt="ComtechRadix"
              width={"100%"}
              height={"100%"}
              style={{ backgroundImage: "auto", padding: "2px 0px 2px 0px" }}
            />
            <h6>
              <br />
              <b> Tech Radix</b>
            </h6>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            className="navbar_toggle"
          />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto nav_bar " style={{ color: textColor }}>
              <Nav.Link
                data-aos="zoom-in"
                style={{ color: textColor }}
                href="/"
                activeClassName="active"
              >
                Home
              </Nav.Link>
              <Nav.Link
                data-aos="zoom-in"
                href="/Aboutus"
                activeClassName="active"
              >
                About
              </Nav.Link>
              <Nav.Link
                data-aos="zoom-in"
                href="/Portfolio"
                activeClassName="active"
              >
                Portfolio
              </Nav.Link>

              <Nav.Link
                data-aos="zoom-in"
                href="/Contact"
                activeClassName="active"
              >
                Contact
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default NavbarHead;
