import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Footer from "./Footer.js";
import NavbarHeader from "./NavbarHeader.js";
import "bootstrap/dist/css/bootstrap.css";
import "./home.css";
import herobg from "../images/herobg7.webp";
import aboutbb from "../images/aboutus.gif";
import furqan from "../images/software_dev.png";
import web from "../images/web_dev.png";
import mobile from "../images/mobile_app.png";
import UIUX from "../images/UI_UX.png";
import digital from "../images/digital.jpg";
import technical from "../images/technical.png";
import WhatsAppButton from "./whatsapp/WhatsAppButton.js";
import Carousel from "./carousel/Carousel.js";
import Team from "./Team.js";
import Faqs from "./faqs/Faqs.js";
import { Helmet } from "react-helmet-async";
function Home() {
  useEffect(() => {
    AOS.init({
      duration: 800,
      once: true,
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Home</title>
        <meta
          name="description"
          content="Com Tech Radix is a team of experienced developers who are passionate about creating innovative software solutions to help businesses and individuals achieve their goals."
        />
        <link rel="canonical" href="/" />
      </Helmet>
      <NavbarHeader />
      <div id="hero" className="hero">
        <div className="container position-relative">
          <div className="row gy-5" data-aos="fade-in">
            <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center text-center text-lg-start">
              <h2 data-AOS="fade-up">
                Welcome to <span>ComTechRadix</span>
              </h2>
              <p>
                <a href="https://www.comtechradix.com/">Com Tech Radix</a> is a
                team of experienced developers who are passionate about creating
                innovative software solutions to help businesses and individuals
                achieve their goals.
              </p>
              <div className="d-flex justify-content-center justify-content-lg-start">
                <a href="/Contact" className="btn-get-started">
                  Get Started
                </a>
              </div>
            </div>
            <div className=" hero_bg col-lg-6 order-1 order-lg-2">
              <img
                src={herobg}
                className="img-fluid"
                height="420px"
                width="420px"
                alt=""
                data-aos="zoom-out"
                data-aos-delay="100"
              />
            </div>
          </div>
        </div>

        <div className="icon-boxes position-relative">
          <div className="container position-relative">
            <div className="row gy-4 mt-5">
              <div
                className="col-xl-3 col-md-6"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="icon-box">
                  <div className="icon">
                    <i className="bi bi-easel" />
                  </div>
                  <h4 className="title" style={{ color: "white" }}>
                    Frontend
                  </h4>
                </div>
              </div>

              <div
                className="col-xl-3 col-md-6"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div className="icon-box">
                  <div className="icon">
                    <i className="bi bi-gem" />
                  </div>
                  <h4 className="title" style={{ color: "white" }}>
                    Backend
                  </h4>
                </div>
              </div>

              <div
                className="col-xl-3 col-md-6"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <div className="icon-box">
                  <div className="icon">
                    <i className="bi bi-geo-alt" />
                  </div>
                  <h4 className="title" style={{ color: "white" }}>
                    WordPress
                  </h4>
                </div>
              </div>

              <div
                className="col-xl-3 col-md-6"
                data-aos="fade-up"
                data-aos-delay="500"
              >
                <div className="icon-box">
                  <div className="icon">
                    <i className="bi bi-command"></i>
                  </div>
                  <h4 className="title" style={{ color: "white" }}>
                    UI / UX
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <Carousel />
      </div>

      <WhatsAppButton />
      <section id="about" className="about">
        <div className="container">
          <div className="section-header">
            <h2 data-aos="fade-up">About Us</h2>
            <p data-aos="zoom-in">
              <a href="https://www.comtechradix.com/">Com Tech Radix's</a>{" "}
              journey began with a vision to deliver high-quality software
              products and services that are tailored to meet the unique needs
              of our clients.
            </p>
          </div>
          <div className="row gy-4">
            <div className="col-lg-6">
              <h4>
                <b data-aos="fade-up">
                  We Value Collaboration, Creativity, and Excellence.
                </b>
              </h4>

              <p data-aos="fade-up">
                At our core,{" "}
                <a href="https://www.comtechradix.com/">Com Tech Radix</a>{" "}
                values collaboration, creativity, and excellence.{" "}
                <a href="https://www.comtechradix.com/">Com Tech Radix</a> works
                closely with our clients to understand their business objectives
                and provide customized solutions that align with their goals.
              </p>
              <p data-aos="fade-up">
                <a href="https://www.comtechradix.com/">Com Tech Radix's</a>{" "}
                team has a diverse range of expertise in various programming
                languages, frameworks, and platforms, allowing us to provide
                comprehensive solutions for a wide range of industries and
                businesses.
              </p>
              <p data-aos="fade-up">
                <a href="https://www.comtechradix.com/">Com Tech Radix</a> is
                dedicated to staying up-to-date with the latest advancements in
                technology and continuously improving our skills to ensure we
                provide the best possible services to our clients.
              </p>
            </div>
            <div className="col-lg-6">
              <div className="content ps-0 ps-lg-5">
                <div
                  className="position-relative about_image_div shadow-lg"
                  data-aos="zoom-in"
                >
                  <img
                    className="img-fluid rounded-4"
                    src={aboutbb}
                    alt="thisisaboutimage"
                  />
                  {/* <a href="" className="glightbox play-btn"></a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* End About Us Section */}

      {/* <!-- ======= 
      Section ======= --> */}
      <section id="services" className="services sections-bg">
        <div className="container">
          <div className="section-header">
            <h2 data-aos="fade-up">Our Services</h2>
            <p data-aos="zoom-in">
              At <a href="https://www.comtechradix.com/">Com Tech Radix</a>, we
              offer a comprehensive range of software development services to
              help businesses of all sizes and industries streamline their
              operations, boost productivity, and stay ahead of the competition.
            </p>
          </div>

          <div className="row gy-4" data-aos-delay="100">
            <div className="col-lg-4 col-md-6" data-aos="fade-up">
              <div className="service-item  position-relative shadow-lg">
                <div>
                  <img src={furqan} alt="furqan" />
                </div>
                <h3>Software Development</h3>
                <p>
                  <a href="https://www.comtechradix.com/">Com Tech Radix</a>{" "}
                  develops customized software solutions that are tailored to
                  your specific business requirements. Our team will work
                  closely with you to understand your needs, design the
                  solution, and develop the software to meet your needs.
                </p>
              </div>
            </div>
            {/* <!-- End Service Item --> */}

            <div className="col-lg-4 col-md-6" data-aos="fade-up">
              <div className="service-item position-relative shadow-lg">
                <div>
                  <img src={web} alt="web" />
                </div>
                <h3>Web Development</h3>
                <p>
                  <a href="https://www.comtechradix.com/">Com Tech Radix</a>{" "}
                  designs and develops scalable web applications that are
                  responsive, user-friendly, and optimized for performance. Our
                  expertise in web development includes front-end development,
                  back-end development, and CMS development.
                </p>
              </div>
            </div>
            {/* <!-- End Service Item --> */}

            <div className="col-lg-4 col-md-6" data-aos="fade-up">
              <div className="service-item position-relative shadow-lg">
                <div>
                  <img src={mobile} alt="mobile" />
                </div>
                <h3>App Development</h3>
                <p>
                  <a href="https://www.comtechradix.com/">Com Tech Radix</a>{" "}
                  specializes in developing mobile applications for iOS and
                  Android platforms. Our team has expertise in developing mobile
                  apps that are user-friendly, intuitive, and engaging. We can
                  help you optimize and redesign your existing mobile app.
                </p>
              </div>
            </div>
            {/* <!-- End Service Item --> */}

            <div className="col-lg-4 col-md-6" data-aos="fade-up">
              <div className="service-item position-relative shadow-lg">
                <div>
                  <img src={UIUX} alt="UIUX" />
                </div>
                <h3>UI/UX Design</h3>
                <p>
                  <a href="https://www.comtechradix.com/">Com Tech Radix's</a>{" "}
                  team of designers creates user-centric designs that are
                  visually appealing and easy to navigate.{" "}
                  <a href="https://www.comtechradix.com/">Com Tech Radix</a>{" "}
                  uses the latest design tools and methodologies to deliver
                  high-quality designs that enhance user experience.
                </p>
              </div>
            </div>
            {/* <!-- End Service Item --> */}

            <div className="col-lg-4 col-md-6" data-aos="fade-up">
              <div className="service-item digital_markeeting position-relative shadow-lg">
                <div>
                  <img src={digital} alt="digital" />
                </div>
                <h3>Digital Marketing</h3>
                <p>
                  At <a href="https://www.comtechradix.com/">Com Tech Radix</a>,
                  we have specialized in providing top-notch digital marketing
                  services to help businesses of all sizes and industries grow
                  their online presence and reach their target audience.
                </p>
              </div>
            </div>
            {/* <!-- End Service Item --> */}

            <div className="col-lg-4 col-md-6" data-aos="fade-up">
              <div className="service-item position-relative shadow-lg">
                <div>
                  <img src={technical} alt="technical" />
                </div>
                <h3>Technical Support</h3>
                <p>
                  Working With Clients, With access to the code,{" "}
                  <a href="https://www.comtechradix.com/">Com Tech Radix</a>{" "}
                  learns your system inside and out.{" "}
                  <a href="https://www.comtechradix.com/">Com Tech Radix</a>{" "}
                  works with you to learn your deployment and document
                  everything for your team to ensure best knowledge transfer.
                </p>
              </div>
            </div>
            {/* <!-- End Service Item --> */}
          </div>
        </div>
      </section>
      {/* End Our Services Section  */}

      {/* <Team /> */}

      <div
        className="pb-2"
        style={{ backgroundColor: "#f6f6f6", paddingTop: "70px" }}
      >
        <div className="section-header">
          <h2 data-aos="fade-up">Meet Our Team</h2>
          <p data-aos="zoom-in">
            These individuals work together to make{" "}
            <a href="https://www.comtechradix.com/">Com Tech Radix</a> function.
          </p>
        </div>
        <Team />
      </div>

      {/* FAQs */}

      <div
        className="pb-5"
        style={{ backgroundColor: "#fff", paddingTop: "70px" }}
      >
        <div className="section-header">
          <h2 data-aos="fade-up">FAQs</h2>
          <p data-aos="zoom-in">
            Get answers to commonly asked questions and find solutions to your
            queries in our comprehensive FAQ section.{" "}
          </p>
        </div>
        <Faqs />
      </div>

      <Footer />
    </>
  );
}

export default Home;
