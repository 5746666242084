import React from 'react';
import Footer from './Footer';
import NavbarHeader from './NavbarHeader';
import './PrivacyPolicy.css';
import { Helmet } from 'react-helmet-async';

const PrivacyPolicy = () => {
  return (
    <>

      <Helmet >
        <title>Privacy Policy</title>
        <meta name="description" content="This privacy policy outlines how our website collects, uses, and protects the information you provide to us when you use our website." />
        <link rel="canonical" href="/Privacypolicy" />
      </Helmet>

      <NavbarHeader />


      <div className='main_privacy'>
        <div className='Privacy_hero'>
          <h1>Privacy Policy</h1>
          <p>This privacy policy outlines how our website collects, uses, and protects the information you provide to us when you use our website.</p>
        </div>
        <div className='container Privacy_content'>
          <h3>What information does <a href='https://www.comtechradix.com/'>ComTechRadix</a> collect?</h3>
          <br></br>
          <p><a href='https://www.comtechradix.com/'>ComTechRadix</a> may collect personal information such as your name, email address, and phone number when you submit a contact form or request a quote. We may also collect non-personal information such as your IP address and browsing history.</p><br></br>
          <h3>How does <a href='https://www.comtechradix.com/'>ComTechRadix</a> use your information?</h3>
          <br></br>
          <p><a href='https://www.comtechradix.com/'>ComTechRadix</a> uses your personal information to respond to your inquiries and provide you with the services you request. We may also use your information to send you marketing materials and to improve our website and services. We do not sell, rent, or trade your personal information to third parties.</p><br></br>
          <h3>How does <a href='https://www.comtechradix.com/'>ComTechRadix</a> protect your information?</h3>
          <br></br>
          <p><a href='https://www.comtechradix.com/'>ComTechRadix</a> takes reasonable measures to protect your information from unauthorized access, disclosure, or destruction. We use secure servers and encryption technology to safeguard your information.</p><br></br>
          <h3>Does <a href='https://www.comtechradix.com/'>ComTechRadix</a> use cookies?</h3>
          <br></br>
          <p>Yes, <a href='https://www.comtechradix.com/'>ComTechRadix</a> uses cookies to improve your browsing experience and analyze website traffic. You can choose to disable cookies in your browser settings, but this may limit your ability to use certain features of our website.</p><br></br>
          <h3>Does <a href='https://www.comtechradix.com/'>ComTechRadix</a> disclose any information to outside parties?</h3>
          <br></br>
          <p><a href='https://www.comtechradix.com/'>ComTechRadix</a> does not sell, trade, or otherwise transfer your personal information to third parties. We may, however, share non-personal information such as website usage statistics with third parties.</p><br></br>
          <h3>Third-party links</h3>
          <br></br>
          <p>Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of these websites.</p><br></br>
          <h3>Changes to our privacy policy</h3>
          <br></br>
          <p><a href='https://www.comtechradix.com/'>ComTechRadix</a> may update our privacy policy from time to time. Any changes will be posted on this page.</p>
        </div>
      </div>

      <Footer />
    </>
  )
}

export default PrivacyPolicy