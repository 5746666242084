import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Portfolio1.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Apiax from "../images/Apiax.png";

const Portfolio1 = () => {
  useEffect(() => {
    AOS.init({
      duration: 800,
      once: true,
    });
  }, []);
  return (
    <div className="port1_main">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-sm-12 port1_left" data-aos="zoom-in-right">
            <a href="#" rel="noreferrer">
              <img className="" src={Apiax} width="100%" alt="Fourthimg" />
            </a>
          </div>
          <div class="col-lg-6 col-sm-12 port1_right" data-aos="zoom-in-down">
            <h2>
              <strong>Raed Al Lail</strong>
            </h2>
            <h4>
              <span>Technical Services LLC Based in UAE.</span>
            </h4>
            <p>
              Call the most trusted maintenance company in Dubai today. The goal
              of bespoke design in home maintenance is to provide homeowners
              with personalized and effective solutions that can help them
              maintain and enhance their homes in the most efficient and
              cost-effective way possible.
            </p>
            {/* <a href="#" target={"_blank"}>
              <Button className="mt-2 shadow-lg portfolio1_btn">
                VISIT SITE
              </Button>
            </a> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Portfolio1;
