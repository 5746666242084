import React, { Component } from "react";
import "./Portfolio22.css";
import Slider from "react-slick";
import cru from "../images/cru.jpg";
import cru2 from "../images/cru2.png";
import cru3 from "../images/cru3.png";
import { Button } from "react-bootstrap";
import DevOpsCarousel from "./carousel/DevOpsCarousel";
const Portfolio22 = () => {
  const settings = {
    className: "center",
    centerMode: true,
    arrows: false,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
  };

  return (
    <div className="container-fluid Port22_main">
      <div className="container">
        <div className="row">
          <div className="col-lg-5  Port22_right">
            <h2>
              <strong>CRU</strong>
            </h2>
            <h4>
              <span>CRU (CRM OF QUALITY Assurance WEB APP of CRU Project)</span>
            </h4>
            <p>
              This web portal is designed for QA professionals to conduct
              quality assurance checks and validate employees based on their
              transcription accuracy percentage. It assesses how well
              individuals align with the criteria provided by the client.
            </p>
            <div className="mt-4">
              <ul className="list-unstyled d-flex flex-row">
                <li className="mx-2 border py-1 px-2 custom-rounded">HTML5</li>
                <li className="mx-2 border py-1 px-2 custom-rounded">Css</li>
                <li className="mx-2 border py-1 px-2 custom-rounded">Ajax</li>
                <li className="mx-2 border py-1 px-2 custom-rounded">JQuery</li>
              </ul>
            </div>

            <div className="mt-4">
              <ul className="list-unstyled d-flex flex-row">
                <li className="mx-2 border py-1 px-2 custom-rounded">
                  Asp.net MVC
                </li>
                <li className="mx-2 border py-1 px-2 custom-rounded">
                  MS Sql Server
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-7  Port22_left">
            <div className="text-center">
              <Slider {...settings}>
                <div>
                  <img src={cru} alt="cru" />
                </div>
                <div>
                  <img src={cru2} alt="cru" />
                </div>
                <div>
                  <img src={cru3} alt="cru" />
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
      <DevOpsCarousel />
    </div>
  );
};

export default Portfolio22;
