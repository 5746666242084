import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Portfolio10.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "react-bootstrap";
import MVVtech from "../images/mvvtech.png";

const Portfolio10 = () => {
  useEffect(() => {
    AOS.init({
      duration: 800,
      once: true,
    });
  }, []);

  return (
    <div
      className="port10_main shadow-lg"
      style={{ backgroundColor: "#313647;" }}
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-sm-12 port10_left" data-aos="zoom-in-right">
            <h2>
              <strong>MVVtech</strong>
            </h2>
            <h4>
              <span>CNC Machine Tool Production Plant.</span>
            </h4>
            <p>
              MVVtech.net Provides the services of selling CNC machine tool
              production plants.
            </p>
            {/* <a href="#" target={"_blank"}>
              <Button className="mt-2 shadow-lg porftfolio10_btn">
                VISIT SITE
              </Button>
            </a> */}
          </div>
          <div class="col-lg-6 col-sm-12 port10_right" data-aos="zoom-in-down">
            <a href="#" target={"_blank"} rel="noreferrer">
              <img src={MVVtech} width="100%" alt="Fourthimg" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Portfolio10;
