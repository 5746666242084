import React from "react";
import './App.css';
import { Routes, Route } from "react-router-dom";
import Home from "./components/Home.js";
import Portfolio from "./pages/Portfolio.js";
import ContactUs from "./pages/ContactUs.js";
import Aboutus from "./components/Aboutus.js";
import PrivacyPolicy from "./components/PrivacyPolicy.js";
import Terms from "./components/Terms.js";

function App() {
  return (
    <>
      <Routes>
        <Route>
          <Route path="/" element={<Home />}></Route>
          <Route path="/Portfolio" element={<Portfolio />}></Route>
          <Route path="/Contact" element={<ContactUs />}></Route>
          <Route path="/Aboutus" element={<Aboutus />}></Route>
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />}></Route>
          <Route path="/Terms" element={<Terms />}></Route>
        </Route>
      </Routes>
    </>
  );
}

export default App;
