import React from "react";
import ContactUsComponent from "../components/ContactUsComponent";
import ContactusHero from "../components/ContactusHero";
import Footer from "../components/Footer";
import NavbarHeader from "../components/NavbarHeader";
import WhatsAppButton from "../components/whatsapp/WhatsAppButton";
import { Helmet } from "react-helmet-async";

function ContactUs() {
  return (
    <div>
      <Helmet >
        <title>Contact</title>
        <meta name="description" content="Com Tech Radix loves to hear from you. Drop us a line. We're just a click away." />
        <link rel="canonical" href="/Contact" />
      </Helmet>
      <NavbarHeader />
      <ContactusHero />
      <WhatsAppButton />
      <ContactUsComponent />
      <Footer />
    </div>
  );
}

export default ContactUs;
