import React, { Component } from "react";
import "./Portfolio17.css";
import Slider from "react-slick";
import PossiblyApp from "../images/portfolio/PossiblyApp.jpg";
import PossiblyApp2 from "../images/portfolio/PossiblyApp2.jpg";

const Portfolio17 = () => {
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    arrows: false,
    centerPadding: "0px",
    slidesToShow: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
  };

  return (
    <div className="container-fluid port17_main">
      <div className="container">
        <div className="row">
          <div className="col-lg-5  port17_right">
            <h2>
              <strong>Possibly App</strong>
            </h2>
            <h4>
              <span>A React Mobile App.</span>
            </h4>
            <p>
              project aims to revolutionize career exploration and professional
              development by offering a comprehensive platform that integrates various core functions. The range of
              smartphone app functions ensures a user-friendly experience,
              combining educational content, company profiles.
            </p>
            <div className="mt-4">
              <ul className="list-unstyled d-flex flex-row">
                <li className="mx-2 border py-1 px-2 custom-rounded">
                  React Native
                </li>
                <li className="mx-2 border py-1 px-2 custom-rounded">Nodejs</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-7  port17_left">
            <div className="text-center">
              <Slider {...settings}>
                <div>
                  <img src={PossiblyApp} alt="Irange1" />
                </div>
                <div>
                  <img src={PossiblyApp2} alt="Irange2" />
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Portfolio17;
