import React, { Component } from "react";
import "./Portfolio20.css";
import Slider from "react-slick";
import impaints from "../images/impaints.png";
import impaints1 from "../images/impaints2.png";
import impaints2 from "../images/impaints3.png";
import { Button } from "react-bootstrap";
const Portfolio20 = () => {
  const settings = {
    className: "center",
    centerMode: true,
    arrows: false,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
  };

  return (
    <div className="container-fluid Port20_main">
      <div className="container">
        <div className="row">
          <div className="col-lg-5  Port20_right">
            <h2>
              <strong>IM Paints</strong>
            </h2>
            <h4>
              <span>We paint like no others.</span>
            </h4>
            <p>
              Transform your spaces with our best interior & exterior
              paints.Elevate your space with a palette that transcends ordinary
              paint, bringing a harmonious blend of style and durability to your
              walls.
            </p>
            <a href="https://impaints.com/" target={"_blank"}>
              <Button className="mt-2 shadow-lg Portfolio20_btn">
                VISIT SITE
              </Button>
            </a>
          </div>
          <div className="col-lg-7  Port20_left">
            <div className="text-center">
              <Slider {...settings}>
                <div>
                  <img src={impaints} alt="impaints" />
                </div>
                <div>
                  <img src={impaints1} alt="eztracking" />
                </div>
                <div>
                  <img src={impaints2} alt="eztracking" />
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Portfolio20;
