import React from 'react';
import Footer from './Footer';
import NavbarHeader from './NavbarHeader';
import './Terms.css';
import { Helmet } from 'react-helmet-async';

const Terms = () => {
  return (
    <>

      <Helmet >
        <title>Terms</title>
        <meta name="description" content="Welcome to our website. By using our website, you agree to these terms and conditions. If you do not agree with these terms and conditions, please do not use our website." />
        <link rel="canonical" href="/Terms" />
      </Helmet>

      <NavbarHeader />


      <div className='main_Terms'>
        <div className='Terms_hero'>
          <h1>Terms & Conditions</h1>
          <p>Welcome to our website. By using our website, you agree to these terms and conditions. If you do not agree with these terms and conditions, please do not use our website.</p>
        </div>
        <div className='container Terms_content'>
          <h3>Use of our website</h3>
          <br></br>
          <p>Our website is intended for personal and non-commercial use. You may not use our website for any illegal or unauthorized purpose. You may not attempt to gain unauthorized access to our website or its servers.</p><br></br>
          <h3>Intellectual Property</h3>
          <br></br>
          <p>All content on our website, including but not limited to software, graphics, logos, and text, is owned by us or our licensors and is protected by copyright and other intellectual property laws. You may not reproduce, modify, distribute, or display any of our content without our prior written consent.</p><br></br>
          <h3>Software Development Services</h3>
          <br></br>
          <p>We offer software development services to our clients. Our services are subject to our separate agreements with our clients. We do not guarantee that our services will meet your specific requirements, and we are not responsible for any damages resulting from the use of our services.</p><br></br>
          <h3>Disclaimer of Warranties</h3>
          <br></br>
          <p>Our website and its content are provided on an "as is" and "as available" basis. We do not make any warranties, express or implied, regarding the operation of our website or the content on our website. We do not guarantee that our website will be error-free or uninterrupted, or that our website or its servers are free of viruses or other harmful components.</p><br></br>
          <h3>Limitation of Liability</h3>
          <br></br>
          <p>We are not liable for any damages, whether direct, indirect, incidental, or consequential, arising from the use of our website or its content. This includes but is not limited to damages resulting from loss of data, profits, or business interruption.</p><br></br>
          <h3>Indemnification</h3>
          <br></br>
          <p>You agree to indemnify and hold us harmless from any claim or demand, including reasonable attorneys' fees, arising out of your use of our website or violation of these terms and conditions.</p><br></br>
          <h3>Governing Law</h3>
          <br></br>
          <p>These terms and conditions are governed by and construed in accordance with the laws. Any dispute arising out of these terms and conditions shall be resolved in the courts.</p><br></br>
          <h3>Changes to these terms and conditions</h3>
          <br></br>
          <p>We reserve the right to modify these terms and conditions at any time without notice. By continuing to use our website after any changes to these terms and conditions, you agree to be bound by the revised terms and conditions.</p><br></br>
          <h3>Contact us</h3>
          <br></br>
          <p>If you have any questions or concerns about these terms and conditions, please contact us at Contact Us page.</p>

        </div>
      </div>

      <Footer />
    </>
  )
}

export default Terms