import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Portfolio8.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "react-bootstrap";
import rainbowsandwindows1 from "../images/rainbowsandwindows1.png";
import rainbowsandwindows2 from "../images/rainbowsandwindows2.png";
import rainbowsandwindows3 from "../images/rainbowsandwindows3.png";
import rainbowsandwindows4 from "../images/rainbowsandwindows4.png";

const Portfolio8 = () => {
  useEffect(() => {
    AOS.init({
      duration: 800,
      once: true,
    });
  }, []);

  return (
    <div
      className="port8_main shadow-lg"
      style={{ backgroundColor: "#313647;" }}
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-sm-12 port8_left" data-aos="zoom-in-right">
            <h2>
              <strong>Rainbows and Windows INC</strong>
            </h2>
            <h4>
              <span>
                Where Individuals, Families, and Communities collectively create
                a world where Everyone Belongs.
              </span>
            </h4>
            <p>
              Ms. Hardeman-Tsadick realized early in her life that she has a
              passion for being the voice for children. That led to her studies
              in School Psychology. Julia has had the opportunity to work as a
              School Psychologist for over 33 years in various settings with all
              ages.
            </p>
            <a href="https://rainbowsandwindows.org/" target={"_blank"}>
              <Button className="mt-2 shadow-lg portfolio8_btn">
                VISIT SITE
              </Button>
            </a>
          </div>
          <div class="col-lg-6 col-sm-12 port8_right" data-aos="zoom-in-down">
            <div class="port8_right_inner">
              <div class="box">
                <a
                  href="https://rainbowsandwindows.org/"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <img
                    src={rainbowsandwindows1}
                    width="100%"
                    alt="Fourthimg"
                  />
                </a>
              </div>
              <div class="box">
                <a
                  href="https://rainbowsandwindows.org/"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <img
                    src={rainbowsandwindows2}
                    width="100%"
                    alt="Fourthimg"
                  />
                </a>
              </div>
              <div class="box">
                <a
                  href="https://rainbowsandwindows.org/"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <img
                    src={rainbowsandwindows3}
                    width="100%"
                    alt="Fourthimg"
                  />
                </a>
              </div>

              <div class="box">
                <a
                  href="https://rainbowsandwindows.org/"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <img
                    src={rainbowsandwindows4}
                    width="100%"
                    alt="Fourthimg"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Portfolio8;
