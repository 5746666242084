import React, { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Portfolio2 from "../components/Portfolio2";
import Portfolio3 from "../components/Portfolio3";
import Portfolio4 from "../components/Portfolio4";
import Portfolio1 from "../components/Portfolio1";
import PortfolioHero from "../components/PortfolioHero";
import Portfolio5 from "../components/Portfolio5";
import NavbarHeader from "../components/NavbarHeader";
import Footer from "../components/Footer";
import Portfolio6 from "../components/Portfolio6";
import Portfolio7 from "../components/Portfolio7";
import Portfolio8 from "../components/Portfolio8";
import Portfolio9 from "../components/Portfolio9";
import Portfolio10 from "../components/Portfolio10";
import Portfolio11 from "../components/Portfolio11";
import Portfolio12 from "../components/Portfolio12";
import Portfolio13 from "../components/Portfolio13";
import WhatsAppButton from "../components/whatsapp/WhatsAppButton";
import Portfolio14 from "../components/Portfolio14";
import Portfolio15 from "../components/Portfolio15";
import "./Portfolio.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDownShortWide,
  faGlobe,
  faMobile,
} from "@fortawesome/free-solid-svg-icons";
import { faWordpress } from "@fortawesome/free-brands-svg-icons";
import Portfolio17 from "../components/Portfolio17";
import Portfolio18 from "../components/Portfolio18";
import Portfolio19 from "../components/Portfolio19";
import Portfolio20 from "../components/Portfolio20";
import Portfolio21 from "../components/Portfolio21";
import Portfolio22 from "../components/Portfolio22";
import Portfolio23 from "../components/Portfolio23";
import Portfolio24 from "../components/Portfolio24";
import { Helmet } from "react-helmet-async";

const Portfolio = () => {
  const [activeTab, setActiveTab] = useState("all");

  useEffect(() => {
    AOS.init({
      duration: 800,
      once: true,
    });
  }, []);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div>

      <Helmet >
        <title>Portfolio</title>
        <meta name="description" content="Welcome to Com Tech Radix, a leading software development company that provides cutting-edge solutions to businesses of all sizes." />
        <link rel="canonical" href="/Portfolio" />
      </Helmet>

      <NavbarHeader />
      <WhatsAppButton />

      <PortfolioHero />

      <div className="demo">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="tab" role="tabpanel">
                <ul className="nav nav-tabs" role="tablist">
                  <li
                    role="presentation"
                    className={activeTab === "all" ? "active" : ""}
                  >
                    <a
                      href="#Section4"
                      aria-controls="messages"
                      role="tab"
                      data-toggle="tab"
                      onClick={() => handleTabChange("all")}
                    >
                      <FontAwesomeIcon icon={faArrowDownShortWide} className="icon-size" />{" "}
                      <span>All</span>
                    </a>
                  </li>
                  <li
                    role="presentation"
                    className={activeTab === "wordpress" ? "active" : ""}
                  >
                    <a
                      href="#Section1"
                      aria-controls="home"
                      role="tab"
                      data-toggle="tab"
                      onClick={() => handleTabChange("wordpress")}
                    >
                      <FontAwesomeIcon icon={faWordpress}  className="icon-size"  />{" "}
                      <span>Wordpress</span>
                    </a>
                  </li>
                  <li
                    role="presentation"
                    className={activeTab === "web" ? "active" : ""}
                  >
                    <a
                      href="#Section2"
                      aria-controls="profile"
                      role="tab"
                      data-toggle="tab"
                      onClick={() => handleTabChange("web")}
                    >
                      <FontAwesomeIcon icon={faGlobe} className="icon-size"  />{" "}
                      <span>Web Dev & Dev Ops</span>
                    </a>
                  </li>
                  <li
                    role="presentation"
                    className={activeTab === "mobile" ? "active" : ""}
                  >
                    <a
                      href="#Section3"
                      aria-controls="messages"
                      role="tab"
                      data-toggle="tab"
                      onClick={() => handleTabChange("mobile")}
                    >
                      <FontAwesomeIcon icon={faMobile} className="icon-size"  />{" "}
                      <span>Mobile Dev</span>
                    </a>
                  </li>
                </ul>

                <div className="tab-content">
                  {activeTab === "wordpress" && (
                    <>
                      <Portfolio2 />
                      <Portfolio3 />
                      <Portfolio1 />
                      <Portfolio5 />
                      <Portfolio6 />
                      <Portfolio7 />
                      <Portfolio8 />
                      <Portfolio9 />
                      <Portfolio10 />
                      <Portfolio11 />
                      <Portfolio12 />
                      <Portfolio13 />
                      <Portfolio14 />
                      <Portfolio15 />
                      <Portfolio20 />
                    </>
                  )}
                  {activeTab === "web" && (
                    <>
                      <Portfolio19 />
                      <Portfolio4 />
                      <Portfolio18 />
                      <Portfolio21 />
                      <Portfolio22 />
                      <Portfolio24 />
                    </>
                  )}
                  {activeTab === "mobile" && (
                    <>
                      <Portfolio17 />
                      <Portfolio23 />
                    </>
                  )}
                  {activeTab === "all" && (
                    <>
                      <Portfolio2 />
                      <Portfolio3 />
                      <Portfolio4 />
                      <Portfolio1 />
                      <Portfolio5 />
                      <Portfolio6 />
                      <Portfolio7 />
                      <Portfolio8 />
                      <Portfolio9 />
                      <Portfolio10 />
                      <Portfolio11 />
                      <Portfolio12 />
                      <Portfolio13 />
                      <Portfolio19 />
                      <Portfolio14 />
                      <Portfolio17 />
                      <Portfolio15 />
                      <Portfolio18 />
                      <Portfolio20 />
                      <Portfolio21 />
                      <Portfolio22 />
                      <Portfolio23 />
                      <Portfolio24 />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Portfolio;
