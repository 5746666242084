import React, { Component } from "react";
import "./Portfolio24.css";
import Slider from "react-slick";
import mtg from "../images/mtg.jpg";
import mtg2 from "../images/mtg2.jpg";
import { Button } from "react-bootstrap";
import DevOpsCarousel from "./carousel/DevOpsCarousel";
const Portfolio24 = () => {
  const settings = {
    className: "center",
    centerMode: true,
    arrows: false,
    vertical: true,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
  };

  return (
    <div className="container-fluid Port24_main">
      <div className="container">
        <div className="row">
          <div className="col-lg-7  Port24_left">
            <div className="text-center">
              <Slider {...settings}>
                <div>
                  <img src={mtg} alt="mtg" />
                </div>
                <div>
                  <img src={mtg2} alt="mtg" />
                </div>
              </Slider>
            </div>
          </div>
          <div className="col-lg-5  Port24_right">
            <h2>
              <strong>MTG EMS</strong>
            </h2>
            <h4>
              <span>MTG EMS for call centers</span>
            </h4>
            <p>
              MTG EMS (Employee Management System) for call centers is a robust
              solution designed to streamline workforce operations. From
              scheduling and performance tracking to task assignment and
              communication, this system enhances overall employee management
              efficiency.
            </p>
            
            <div className="mt-4">
              <ul className="list-unstyled d-flex flex-row">
                <li className="mx-2 border py-1 px-2 custom-rounded">
                  HTML5
                </li>
                <li className="mx-2 border py-1 px-2 custom-rounded">Css</li>
                <li className="mx-2 border py-1 px-2 custom-rounded">
                  Ajax
                </li>
                <li className="mx-2 border py-1 px-2 custom-rounded">
                  PHP
                </li>
              </ul>
            </div>
            <div className="mt-4">
              <ul className="list-unstyled d-flex flex-row">
                <li className="mx-2 border py-1 px-2 custom-rounded">
                  JQuery
                </li>
                <li className="mx-2 border py-1 px-2 custom-rounded">
                  MySql
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <DevOpsCarousel />
    </div>
  );
};

export default Portfolio24;
