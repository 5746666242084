import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Portfolio5.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "react-bootstrap";
import us121 from "../images/us121.png";

const Portfolio5 = () => {
  useEffect(() => {
    AOS.init({
      duration: 800,
      once: true,
    });
  }, []);

  return (
    <div
      className="port_main5 shadow-lg"
      style={{ backgroundColor: "#313647;" }}
    >
      <div class="container">
        <div class="row">
          <div
            className="col-lg-6 col-sm-12 port5_left "
            data-aos="zoom-in-right"
          >
            <ul>
              <li>
                <div class="image">
                  <a href="https://121.us/" target={"_blank"} rel="noreferrer">
                    <img src={us121} width="100%" alt="Fourthimg" />
                  </a>
                </div>
                <div class="shadow"></div>
              </li>
            </ul>
          </div>
          <div class="col-lg-6 col-sm-12 port5_right" data-aos="zoom-in-down">
            <h2>
              <strong>121 Us</strong>
            </h2>
            <h4>
              <span>121 US is parent company present in USA.</span>
            </h4>
            <p>
              121 US has three subsidiaries, SmarterMergers: Acquire, Sell or
              Finance Businesses and Properties, 121 Solutions: World Class
              Experience in Technology, Consulting and Software & BrookerCoop:
              It's all about how you use your money.{" "}
            </p>
            <a href="https://121.us/" target={"_blank"}>
              <Button className="mt-2 shadow-lg portfolio5_btn">
                VISIT SITE
              </Button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Portfolio5;
