import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./ContactusHero.css";
import "bootstrap/dist/css/bootstrap.min.css";

const ContactusHero = () => {
  useEffect(() => {
    AOS.init({
      duration: 800,
      once: true,
    });
  }, []);
  return (
    <div className="ContactusHero">
      <h1 data-aos="fade-up">Contact Us</h1>
      <p data-aos="zoom-in">
        <a href="https://www.comtechradix.com/">Com Tech Radix</a> loves to hear
        from you. Drop us a line. We're just a click away.
      </p>
    </div>
  );
};

export default ContactusHero;
