import React, { Component } from "react";
import "./Portfolio23.css";
import Slider from "react-slick";
import health from "../images/health.jpg";
import { Button } from "react-bootstrap";
const Portfolio23 = () => {
  const settings = {
    className: "center",
    centerMode: true,
    vertical: true,
    arrows: false,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
  };

  return (
    <div className="container-fluid Port23_main">
      <div className="container">
        <div className="row">
          <div className="col-lg-7  Port23_left">
            <div className="text-center">
              <Slider {...settings}>
                <div>
                  <img src={health} alt="health" />
                </div>
                <div>
                  <img src={health} alt="health" />
                </div>
              </Slider>
            </div>
          </div>
          <div className="col-lg-5  Port23_right">
            <h2>
              <strong>Habbits App</strong>
            </h2>
            <h4>
              <span>A Health Tracking App.</span>
            </h4>
            <p>
              The Health Tracking App is a comprehensive and user-friendly
              mobile application designed to empower individuals in managing
              their well-being. With intuitive features, the app allows users to
              effortlessly monitor and record various health metrics.
            </p>
            <div className="mt-4">
              <ul className="list-unstyled d-flex flex-row">
                <li className="mx-2 border py-1 px-2 custom-rounded">
                  React Native
                </li>
                <li className="mx-2 border py-1 px-2 custom-rounded">Nodejs</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Portfolio23;
