import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const WhatsAppButton = () => {
  const handleClick = () => {
    window.open("https://wa.link/nmjaor", "_blank");
  };

  return (
    <div
      style={{
        position: "fixed",
        bottom: "20px",
        left: "20px",
        zIndex: "9999",
        backgroundColor: "#25D366",
        color: "#fff",
        borderRadius: "50%",
        width: "60px",
        height: "60px",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.25)",
      }}
      onClick={handleClick}
    >
      <FontAwesomeIcon icon={faWhatsapp} style={{ fontSize: "30px" }} />
    </div>
  );
};

export default WhatsAppButton;
