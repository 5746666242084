import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Portfolio6.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "react-bootstrap";
import Atk from "../images/fivescents.png";

const Portfolio6 = () => {
  useEffect(() => {
    AOS.init({
      duration: 800,
      once: true,
    });
  }, []);

  return (
    <div
      className="port6_main shadow-lg"
      style={{ backgroundColor: "#313647;" }}
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-sm-12 port6_left" data-aos="zoom-in-right">
            <h2>
              <strong>Five Scents</strong>
            </h2>
            <h4>
              <span>Raining Offers For New Launch!</span>
            </h4>
            <p>
              Five Scents is an E-Commerce Store located in Pakistan. Five
              Scents' forte is Quality over quantity. They provide you with the
              best quality products at an affordable price.
            </p>
            {/* <a href="#" target={"_blank"}>
              <Button className="mt-2 shadow-lg porftfolio6_btn">
                VISIT SITE
              </Button>
            </a> */}
          </div>
          <div class="col-lg-6 col-sm-12 port6_right" data-aos="zoom-in-down">
            <a href="#" target={"_blank"} rel="noreferrer">
              <img src={Atk} width="100%" alt="Fourthimg" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Portfolio6;
