import React, { useState } from 'react';
import './GoToTopButton.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

const GoToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    if (window.pageYOffset > 200) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  window.addEventListener('scroll', handleScroll);

  return (
    <button
      className={`go-to-top-button ${isVisible ? 'visible' : ''}`}
      onClick={handleClick}
    >
      <i className=""><FontAwesomeIcon icon={faArrowUp} /></i>
    </button>
  );
};

export default GoToTopButton;
