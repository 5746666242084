import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Accordion } from "react-bootstrap";
import FaqsImg from "../../images/FaqsImg.png";
import "./Faqs.css";

function Faqs() {
  useEffect(() => {
    AOS.init({
      duration: 800,
      once: true,
    });
  }, []);
  return (
    <div className="container">
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-6">
          <img
            height="100%"
            width="100%"
            src={FaqsImg}
            alt="Faqs"
            data-aos="zoom-in"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6">
          <Accordion defaultActiveKey="0" className="custom-accordion">
            <Accordion.Item eventKey="0" data-aos="zoom-in">
              <Accordion.Header className="accordion-header">
                1. What services do you provide?
              </Accordion.Header>
              <Accordion.Body className="accordion-body">
                Our company offers a range of services including software
                development, website development, app development, and UI/UX
                design. We specialize in creating tailored solutions to meet
                your specific needs.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1" data-aos="zoom-in">
              <Accordion.Header className="accordion-header">
                2. How do you ensure the quality of your services?
              </Accordion.Header>
              <Accordion.Body className="accordion-body">
                We prioritize quality in everything we do. Our team of
                experienced professionals follows industry best practices,
                conducts rigorous testing, and ensures that each project goes
                through a thorough quality assurance process.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2" data-aos="zoom-in">
              <Accordion.Header className="accordion-header">
                3. What industries do you have experience working with?
              </Accordion.Header>
              <Accordion.Body className="accordion-body">
                We have worked with clients from various industries, including
                but not limited to healthcare, finance, e-commerce, education,
                and entertainment. Our diverse experience enables us to
                understand unique industry requirements and deliver effective
                solutions.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3" data-aos="zoom-in">
              <Accordion.Header className="accordion-header">
                4. Can you handle both small and large-scale projects?
              </Accordion.Header>
              <Accordion.Body className="accordion-body">
                Yes, we have experience working on projects of all sizes.
                Whether you have a small project or a large-scale enterprise
                solution, we have the expertise and scalability to meet your
                requirements.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4" data-aos="zoom-in">
              <Accordion.Header className="accordion-header">
                5. What technologies do you work with?
              </Accordion.Header>
              <Accordion.Body className="accordion-body">
                We stay up-to-date with the latest technologies and frameworks
                in the industry. Our team is proficient in a wide range of
                technologies, including but not limited to HTML5, CSS3, PHP,
                Reactjs, Python, JavaScript, React Native, Node js etc.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5" data-aos="fade-up">
              <Accordion.Header className="accordion-header">
                6. Can you assist with ongoing support and maintenance?
              </Accordion.Header>
              <Accordion.Body className="accordion-body">
                Absolutely! We offer ongoing support and maintenance services to
                ensure your software, website, or app runs smoothly after the
                initial development phase. We provide timely updates, security
                patches, bug fixes, and technical assistance as needed.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </div>
  );
}

export default Faqs;
