import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Portfolio4.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "react-bootstrap";
import errund2 from "../images/errund2.png";
import errund3 from "../images/errund3.png";
import errund4 from "../images/errund4.png";
import errund5 from "../images/errund5.png";
import errundadmin from "../images/errundadmin.png";
import DevOpsCarousel from "./carousel/DevOpsCarousel";

const Portfolio4 = () => {
  useEffect(() => {
    AOS.init({
      duration: 800,
      once: true,
    });
  }, []);

  return (
    <div
      className="port4_main shadow-lg"
      style={{ backgroundColor: "#313647;" }}
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-sm-12 port4_left" data-aos="zoom-in-right">
            <h2>
              <strong>ERRANDSRUNNER</strong>
            </h2>
            <h4>
              <span>LIFE IS EITHER A GREAT ADVENTURE OR NOTHING</span>
            </h4>
            <p>
              ERRANDSRUNNER is one of the most trusted, efficient, and
              professional concierge service providers. Backed by an expert team
              with a wealth of experience in the service industry.
            </p>
            {/* <a href="#" target={"_blank"}>
              <Button className="shadow-lg portfolio4_btn">
                VISIT SITE
              </Button>
            </a> */}
            <div className="mt-4">
              <ul className="list-unstyled d-flex flex-row">
                <li className="mx-2 border py-1 px-2 custom-rounded">
                  HTML5
                </li>
                <li className="mx-2 border py-1 px-2 custom-rounded">PHP</li>
                <li className="mx-2 border py-1 px-2 custom-rounded">
                  AJAX
                </li>
              </ul>
            </div>
            <div className="mt-2">
              <ul className="list-unstyled d-flex flex-row">
                <li className="mx-2 border py-1 px-2 custom-rounded">
                  Bootstrap
                </li>
                <li className="mx-2 border py-1 px-2 custom-rounded">Google Map Api.</li>
                <li className="mx-2 border py-1 px-2 custom-rounded">
                  AJAX
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-6 col-sm-12 port4_right" data-aos="zoom-in-down">
            <div class="port4_right_inner">
              <div class="box">
                <a
                  href="#"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <img src={errund3} width="100%" alt="Fourthimg" />
                </a>
              </div>
              <div class="box">
                <a
                  href="#"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <img src={errund4} width="100%" alt="Fourthimg" />
                </a>
              </div>
              <div class="box">
                <a
                  href="#"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <img src={errund5} width="100%" alt="Fourthimg" />
                </a>
              </div>

              <div class="box">
                <a
                  href="#"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <img src={errundadmin} width="100%" alt="Fourthimg" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DevOpsCarousel />
    </div>
  );
};

export default Portfolio4;
