import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Portfolio15.css";
import "bootstrap/dist/css/bootstrap.min.css";
// import { Button } from 'react-bootstrap';
import { Component } from "react";
import Slider from "react-slick";
import Babyproduct1 from "../images/babyproduct1.PNG";
import Babyproduct2 from "../images/babyproduct2.PNG";
import Babyproduct3 from "../images/babyproduct3.PNG";
import Babyproduct4 from "../images/babyproduct4.PNG";
import Babyproduct5 from "../images/babyproduct5.PNG";

const Portfolio15 = () => {
  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    beforeChange: function (currentSlide, nextSlide) {
      console.log("before change", currentSlide, nextSlide);
    },
    afterChange: function (currentSlide) {
      console.log("after change", currentSlide);
    },
  };
  useEffect(() => {
    AOS.init({
      duration: 800,
      once: true,
    });
  }, []);
  return (
    <div className="port15_main">
      <div class="container">
        <div class="row">
          <div class="col-lg-7 col-sm-12 port15_left" data-aos="zoom-in-right">
            <div>
              <Slider {...settings}>
                <div>
                  <img src={Babyproduct1} alt="babyproduct" />
                </div>
                <div>
                  <img src={Babyproduct2} alt="babyproduct" />
                </div>
                <div>
                  <img src={Babyproduct3} alt="babyproduct" />
                </div>
                <div>
                  <img src={Babyproduct4} alt="babyproduct" />
                </div>
                <div>
                  <img src={Babyproduct5} alt="babyproduct" />
                </div>
              </Slider>
            </div>
          </div>
          <div class="col-lg-5 col-sm-12 port15_right" data-aos="zoom-in-down">
            <h2>
              <strong>Baby Products</strong>
            </h2>
            <h4>
              <span>
                Welcome to our one-stop destination for all your baby product
                needs.
              </span>
            </h4>
            <p>
              Discover a world of wonders for your little one on our baby
              products website, where every item is designed with their safety
              and happiness in mind.Browse our extensive collection of
              top-quality baby products, meticulously curated to make parenting
              a joyful journey.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Portfolio15;
