import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Portfolio12.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "react-bootstrap";
import Azormi1 from "../images/Azormi1.png";
import Azormi2 from "../images/Azormi2.png";
import Azormi3 from "../images/Azormi3.png";
import Azormi4 from "../images/Azormi4.png";

const Portfolio12 = () => {
  useEffect(() => {
    AOS.init({
      duration: 800,
      once: true,
    });
  }, []);

  return (
    <div
      className="port12_main shadow-lg"
      style={{ backgroundColor: "#313647;" }}
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-sm-12 port12_left" data-aos="zoom-in-right">
            <h2>
              <strong>AZORMI</strong>
            </h2>
            <h4>
              <span>Welcome to Azormi Premium Products</span>
            </h4>
            <p>
              Looking for a stylish and practical way to enjoy your coffee or
              tea? Look no further than our Sublimation Tumblers! Plus, it's
              easy to supplement with tissues. The high quality leather makes it
              durable and long lasting.
            </p>
            <a href="https://azormi.com/" target={"_blank"}>
              <Button className="mt-2 shadow-lg portfolio12_btn">
                VISIT SITE
              </Button>
            </a>
          </div>
          <div class="col-lg-6 col-sm-12 port12_right" data-aos="zoom-in-down">
            <div class="port12_right_inner">
              <div class="box">
                <a
                  href="https://azormi.com/"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <img src={Azormi1} width="100%" alt="Fourthimg" />
                </a>
              </div>
              <div class="box">
                <a
                  href="https://azormi.com/"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <img src={Azormi2} width="100%" alt="Fourthimg" />
                </a>
              </div>
              <div class="box">
                <a
                  href="https://azormi.com/"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <img src={Azormi3} width="100%" alt="Fourthimg" />
                </a>
              </div>

              <div class="box">
                <a
                  href="https://azormi.com/"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <img src={Azormi4} width="100%" alt="Fourthimg" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Portfolio12;
