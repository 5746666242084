import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Aboutus.css";
import NavbarHeader from "../components/NavbarHeader";
import Footer from "../components/Footer";
import AboutUsHero from "./AboutUsHero";
import GoToTopButton from "./utils/GoToTopButton";
import WhatsAppButton from "./whatsapp/WhatsAppButton";
import { Helmet } from "react-helmet-async";

const Aboutus = () => {
  useEffect(() => {
    AOS.init({
      duration: 800,
      once: true,
    });
  }, []);
  return (
    <>
      <Helmet>
        <title>About Us</title>
        <meta
          name="description"
          content="Welcome to Com Tech Radix, a leading software development company that provides cutting-edge solutions to businesses of all sizes."
        />
        <link rel="canonical" href="/Aboutus" />
      </Helmet>
      <NavbarHeader />
      <WhatsAppButton />
      <GoToTopButton />
      <AboutUsHero />
      <div class="container aboutus_main">
        <p data-aos="fade-up">
          Welcome to <a href="https://www.comtechradix.com/">Com Tech Radix</a>,
          a leading software development company that provides cutting-edge
          solutions to businesses of all sizes. Our team of experienced
          developers and designers is dedicated to creating innovative software
          products that meet the unique needs of our clients.<br></br>
          <br></br>
          At <a href="https://www.comtechradix.com/">Com Tech Radix</a>, we
          believe that technology can help businesses achieve their goals faster
          and more efficiently. That's why we specialize in developing custom
          software solutions that are tailored to your specific requirements.
          Whether you need a mobile app, web application, or desktop software,
          we have the expertise to deliver quality results that exceed your
          expectations.
          <br></br>
          <br></br>
          Our team is passionate about software development, and we stay
          up-to-date with the latest technologies and trends to ensure that our
          clients receive the best possible solutions. We take pride in our work
          and strive for excellence in everything we do. Our commitment to
          quality, reliability, and customer satisfaction has earned us a
          reputation as one of the best software development companies in the
          industry.
          <br></br>
          <br></br>
          <a href="https://www.comtechradix.com/">Com Tech Radix</a> works
          closely with our clients to understand their needs and goals, and we
          offer flexible solutions that can adapt to changing business
          requirements. Our development process is transparent, and we keep our
          clients informed at every stage of the project. We value
          communication, collaboration, and teamwork, and we believe that these
          qualities are essential for successful software development.
          <br></br>
          <br></br>
          At <a href="https://www.comtechradix.com/">Com Tech Radix</a>, we are
          more than just a software development company. We are a team of
          problem solvers, innovators, and collaborators who are passionate
          about creating software products that make a difference. Contact us
          today to learn more about our services and how we can help your
          business grow.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default Aboutus;
