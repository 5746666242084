import React from "react";
import Slider from "react-slick";
import ShopifyPartners from "../../images/ShopifyPartners.png";
import WordPress from "../../images/WordPress.png";
import ReactLogo from "../../images/ReactLogo.png";
import NodejsLogo from "../../images/NodejsLogo.png";
import MongoDbLogo from "../../images/MongoDbLogo.png";
import PythonLogo from "../../images/PythonLogo.png";

const Carousel = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="container">
      <Slider {...settings}>
        <div>
          <img src={ShopifyPartners} alt="ShopifyPartners" />
        </div>
        <div>
          <img src={ReactLogo} alt="ReactLogo" />
        </div>
        <div>
          <img src={NodejsLogo} alt="NodejsLogo" />
        </div>
        <div>
          <img src={MongoDbLogo} alt="MongoDbLogo" />
        </div>
        <div>
          <img src={PythonLogo} alt="PythonLogo" />
        </div>
        <div>
          <img src={WordPress} alt="WordPress" />
        </div>
      </Slider>
    </div>
  );
};

export default Carousel;
