import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Team.css";
import Suleman from "../images/Suleman.png";
import maaz from "../images/maaz.png";
import Danish from "../images/Danish.png";
import Ehtisham from "../images/Ehtisham.png";
import Sidra from "../images/Sidra.png";
import Furqan from "../images/furqan.png";
import Faizan from "../images/Faizan.png";
import hamza from "../images/hamza.png";
import Talha from "../images/talhawaleed.jpg";

const Team = () => {
  useEffect(() => {
    AOS.init({
      duration: 800,
      once: true,
    });
  }, []);
  return (
    <div className="" style={{ backgroundColor: "#f6f6f6", paddingTop: "0px" }}>
      <section id="team" className="pb-5">
        <div className="container">
          <div className="row">
            {/* Maaz */}
            <div className="col-xs-12 col-sm-6 col-md-6" data-aos="fade-up">
              <div className="image-flip">
                <div className="mainflip flip-0">
                  <div className="frontside">
                    <div className="card shadow-lg">
                      <div className="card-body text-center">
                        <p>
                          <img
                            className=""
                            style={{ backgroundColor: "grey" }}
                            src={maaz}
                            width="100%"
                            alt="Fourthimg"
                          />
                        </p>
                        <h4 className="card-title">Maaz Khalid Bhatti</h4>
                        <p className="card-text">Software Engineer (CE0)</p>
                        <a href="#" className="btn btn_color btn-sm">
                          <i className="fa fa-plus"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="backside">
                    <div className="card shadow-lg">
                      <div className="card-body text-center mt-4">
                        <h4 className="card-title">Maaz Khalid Bhatti</h4>
                        <p className="card-text">
                          CEO{" "}
                          <a href="https://www.comtechradix.com/">
                            Com Tech Radix
                          </a>{" "}
                          Entrepreneur, Strategic Planner, New Ventures, IT
                          Consultar,{" "}
                          <a href="https://www.comtechradix.com/">
                            Web Apps Developer
                          </a>
                          , BPO Portals.
                        </p>
                        <ul className="list-inline">
                          <li className="list-inline-item">
                            <a
                              className="social-icon text-xs-center"
                              target="_blank"
                              href="https://www.linkedin.com/in/maaz-khalid-bhatti-2a087abb/?originalSubdomain=pk"
                            >
                              <i className="fa fa-linkedin"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Suleman */}
            <div className="col-xs-12 col-sm-6 col-md-6" data-aos="fade-up">
              <div
                className="image-flip"
                ontouchstart="this.classNameList.toggle('hover');"
              >
                <div className="mainflip">
                  <div className="frontside">
                    <div className="card shadow-lg">
                      <div className="card-body text-center">
                        <p>
                          <img
                            className=""
                            src={Suleman}
                            width="100%"
                            alt="Fourthimg"
                          />
                        </p>
                        <h4 className="card-title">Muhammad Suleman</h4>
                        <p className="card-text">Co-Founder</p>
                        <a href="#" className="btn btn_color btn-sm">
                          <i className="fa fa-plus"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="backside">
                    <div className="card shadow-lg">
                      <div className="card-body text-center mt-4">
                        <h4 className="card-title">Muhammad Suleman</h4>
                        <p className="card-text">
                          History of working in the outsourcing/offshoring
                          industry and in different Freelance markets place.
                        </p>
                        <ul className="list-inline">
                          <li className="list-inline-item">
                            <a
                              className="social-icon text-xs-center"
                              target="_blank"
                              href="https://www.linkedin.com/in/iammuhammadsuleman/"
                            >
                              <i className="fa fa-linkedin"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Hamza */}
            <div className="col-xs-12 col-sm-6 col-md-4" data-aos="fade-up">
              <div
                className="image-flip"
                ontouchstart="this.classNameList.toggle('hover');"
              >
                <div className="mainflip">
                  <div className="frontside">
                    <div className="card shadow-lg">
                      <div className="card-body text-center">
                        <p>
                          <img
                            className=""
                            style={{ backgroundColor: "#008374" }}
                            src={hamza}
                            width="100%"
                            alt="hamza"
                          />
                        </p>
                        <h4 className="card-title">Hamza Ramzan</h4>
                        <p className="card-text">
                          Blockchain Backend & DevOps engineer
                        </p>
                        <a href="#" className="btn btn_color btn-sm">
                          <i className="fa fa-plus"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="backside">
                    <div className="card shadow-lg">
                      <div className="card-body text-center mt-4">
                        <h4 className="card-title">Hamza Ramzan</h4>
                        <p className="card-text">
                          Hamza Ramzan is a proficient Backend & DevOps Engineer
                          specializing in Node.js, MongoDB, Blockchain, AWS, and
                          Docker. His expertise in infrastructure automation and
                          system monitoring ensures seamless and efficient
                          project execution.
                        </p>
                        <ul className="list-inline">
                          <li className="list-inline-item">
                            <a
                              className="social-icon text-xs-center"
                              target="_blank"
                              href="https://www.linkedin.com/in/hamzaramzan708/"
                            >
                              <i className="fa fa-linkedin"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Furqan */}
            <div className="col-xs-12 col-sm-6 col-md-4" data-aos="fade-up">
              <div
                className="image-flip"
                ontouchstart="this.classNameList.toggle('hover');"
              >
                <div className="mainflip">
                  <div className="frontside">
                    <div className="card shadow-lg">
                      <div className="card-body text-center">
                        <p>
                          <img
                            className=""
                            style={{ backgroundColor: "grey" }}
                            src={Furqan}
                            width="100%"
                            alt="Wahab"
                          />
                        </p>
                        <h4 className="card-title">Rana Furqan</h4>
                        <p className="card-text">Full-Stack Developer</p>
                        <a href="#" className="btn btn_color btn-sm">
                          <i className="fa fa-plus"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="backside">
                    <div className="card shadow-lg">
                      <div className="card-body text-center mt-4">
                        <h4 className="card-title">Rana Furqan</h4>
                        <p className="card-text">
                          A passionate Full-Stack developer with a discerning
                          eye for exquisite design, adept at crafting visually
                          captivating and intuitively engaging web experiences.
                        </p>
                        <ul className="list-inline">
                          <li className="list-inline-item">
                            <a
                              className="social-icon text-xs-center"
                              target="_blank"
                              href="https://www.linkedin.com/in/rana-furqan/?originalSubdomain=pk"
                            >
                              <i className="fa fa-linkedin"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* {Faizan} */}
            <div className="col-xs-12 col-sm-6 col-md-4" data-aos="fade-up">
              <div
                className="image-flip"
                ontouchstart="this.classNameList.toggle('hover');"
              >
                <div className="mainflip">
                  <div className="frontside">
                    <div className="card shadow-lg">
                      <div className="card-body text-center">
                        <p>
                          <img
                            className=""
                            src={Faizan}
                            width="100%"
                            alt="Fourthimg"
                          />
                        </p>
                        <h4 className="card-title">Muhammad Faizan</h4>
                        <p className="card-text">Frontend Developer</p>
                        <a href="#" className="btn btn_color btn-sm">
                          <i className="fa fa-plus"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="backside">
                    <div className="card shadow-lg">
                      <div className="card-body text-center mt-4">
                        <h4 className="card-title">Muhammad Faizan</h4>
                        <p className="card-text">
                          Experienced frontend developer with a strong focus on
                          React Native app development. Proficient in creating
                          visually appealing and highly performant mobile
                          applications for both iOS and Android platforms
                        </p>
                        <ul className="list-inline">
                          <li className="list-inline-item">
                            <a
                              className="social-icon text-xs-center"
                              target="_blank"
                              href="https://www.linkedin.com/in/faizan-mehmood-541951297/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                            >
                              <i className="fa fa-linkedin"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Ehtisham */}
            <div className="col-xs-12 col-sm-6 col-md-3" data-aos="fade-up">
              <div
                className="image-flip"
                ontouchstart="this.classNameList.toggle('hover');"
              >
                <div className="mainflip">
                  <div className="frontside">
                    <div className="card shadow-lg">
                      <div className="card-body text-center">
                        <p>
                          <img
                            className=""
                            style={{ backgroundColor: "grey" }}
                            src={Ehtisham}
                            width="100%"
                            alt="Ehtisham"
                          />
                        </p>
                        <h4 className="card-title">Ehtisham Ul Haq</h4>
                        <p className="card-text">React Developer</p>
                        <a href="#" className="btn btn_color btn-sm">
                          <i className="fa fa-plus"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="backside">
                    <div className="card shadow-lg">
                      <div className="card-body text-center mt-4">
                        <h4 className="card-title">Ehtisham Ul Haq</h4>
                        <p className="card-text">
                          An enthusiastic React developer with a keen eye for
                          design, proficient in delivering visually stunning and
                          user-friendly web designs.
                        </p>
                        <ul className="list-inline">
                          <li className="list-inline-item">
                            <a
                              className="social-icon text-xs-center"
                              target="_blank"
                              href="https://www.linkedin.com/in/ehtisham-ul-haq-7326021a1/"
                            >
                              <i className="fa fa-linkedin"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Sidra */}
            <div
              className="col-xs-12 col-sm-6 col-md-3 text-center"
              data-aos="fade-up"
            >
              <div
                className="image-flip"
                ontouchstart="this.classNameList.toggle('hover');"
              >
                <div className="mainflip">
                  <div className="frontside">
                    <div className="card shadow-lg">
                      <div className="card-body text-center">
                        <p>
                          <img
                            className=""
                            style={{ backgroundColor: "grey" }}
                            src={Sidra}
                            width="100%"
                            alt="Wahab"
                          />
                        </p>
                        <h4 className="card-title">Sidra</h4>
                        <p className="card-text">WordPress Developer</p>
                        <a href="#" className="btn btn_color btn-sm">
                          <i className="fa fa-plus"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="backside">
                    <div className="card shadow-lg">
                      <div className="card-body text-center mt-4">
                        <h4 className="card-title">Sidra</h4>
                        <p className="card-text">
                          Experienced WordPress developer with a passion for
                          creating customizable and user-friendly websites that
                          meet client's unique needs.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Danish */}
            <div className="col-xs-12 col-sm-6 col-md-3" data-aos="fade-up">
              <div
                className="image-flip"
                ontouchstart="this.classNameList.toggle('hover');"
              >
                <div className="mainflip">
                  <div className="frontside">
                    <div className="card shadow-lg">
                      <div className="card-body text-center">
                        <p>
                          <img
                            className=""
                            style={{ backgroundColor: "grey" }}
                            src={Danish}
                            width="100%"
                            alt="Wahab"
                          />
                        </p>
                        <h4 className="card-title">Danish Zaman</h4>
                        <p className="card-text">Graphics Designer</p>
                        <a href="#" className="btn btn_color btn-sm">
                          <i className="fa fa-plus"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="backside">
                    <div className="card shadow-lg">
                      <div className="card-body text-center mt-4">
                        <h4 className="card-title">Danish Zaman</h4>
                        <p className="card-text">
                          Danish Zaman is a talented graphics designer who
                          brings creativity and visual excellence to every
                          project.
                        </p>
                        <ul className="list-inline">
                          <li className="list-inline-item">
                            <a
                              className="social-icon text-xs-center"
                              target="_blank"
                              href="https://www.linkedin.com/in/danish-zaman-b37a8725a/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                            >
                              <i className="fa fa-linkedin"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* {Talha} */}
            <div className="col-xs-12 col-sm-6 col-md-3" data-aos="fade-up">
              <div
                className="image-flip"
                ontouchstart="this.classNameList.toggle('hover');"
              >
                <div className="mainflip">
                  <div className="frontside">
                    <div className="card shadow-lg">
                      <div className="card-body text-center">
                        <p>
                          <img
                            className=""
                            src={Talha}
                            width="100%"
                            alt="talha"
                          />
                        </p>
                        <h4 className="card-title">Talha Waleed</h4>
                        <p className="card-text">React Developer</p>
                        <a href="#" className="btn btn_color btn-sm">
                          <i className="fa fa-plus"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="backside">
                    <div className="card shadow-lg">
                      <div className="card-body text-center mt-4">
                        <h4 className="card-title">Talha Waleed</h4>
                        <p className="card-text">
                          Talha Waleed is a React developer with a passion for
                          creating dynamic and user-friendly web and mobile applications.
                        </p>
                        <ul className="list-inline">
                          <li className="list-inline-item">
                            <a
                              className="social-icon text-xs-center"
                              target="_blank"
                              href="https://www.linkedin.com/in/talhawaleed/"
                            >
                              <i className="fa fa-linkedin"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Team;
