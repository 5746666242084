import React, { Component } from "react";
import "./Portfolio14.css";
import Slider from "react-slick";
import Cellcity2 from "../images/cellcity2.png";
import Cellcity3 from "../images/cellcity3.png";
import Cellcity4 from "../images/cellcity4.png";
import Cellcity5 from "../images/cellcity5.png";
import Cellcity6 from "../images/cellcity6.png";
import Cellcity7 from "../images/cellcity7.png";

export default class Portfolio14 extends Component {
  render() {
    const settings = {
      className: "center",
      centerMode: true,
      infinite: true,
      centerPadding: "0px",
      slidesToShow: 1,
      autoplay: true,
      arrows: false,
      speed: 2000,
      autoplaySpeed: 2000,
    };
    return (
      <div className="container-fluid port14_main">
        <div className="container">
          <div className="row">
            <div className="col-lg-5  port14_right">
              <h2>
                <strong>Cell City</strong>
              </h2>
              <h4>
                <span>Mobile repairing website.</span>
              </h4>
              <p>
                Bringing life back to your mobile devices, one repair at a
                time.Cracked screen? Battery drain? We've got you covered with
                expert mobile repairs!Unlocking potential, fixing glitches, and
                restoring functionality – your trusted mobile repair experts
              </p>
            </div>
            <div className="col-lg-7  port14_left">
              <div className="text-center">
                <Slider {...settings}>
                  <div>
                    <img src={Cellcity2} alt="cellcity2" />
                  </div>
                  <div>
                    <img src={Cellcity3} alt="cellcity3" />
                  </div>
                  <div>
                    <img src={Cellcity4} alt="cellcity4" />
                  </div>
                  <div>
                    <img src={Cellcity5} alt="cellcity5" />
                  </div>
                  <div>
                    <img src={Cellcity6} alt="cellcity6" />
                  </div>
                  <div>
                    <img src={Cellcity7} alt="cellcity7" />
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
